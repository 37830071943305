/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { fetchCallbacks } from '../../hooks/fetch-hooks/get-all-callbacks/fetch-callbacks';
import TableLayout from './table/tableLayout';
import { Columns } from './table/constants';
import { CustomModal } from './create-order-modal/create-order-modal';
import { CallBackContext } from '../../contexts';
import { DeleteOrderModal } from './deleteOrder/deleteOrderModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';

function RequestCallbacks () {

    const urlParams = new URLSearchParams(location.search);
    const page = parseInt(urlParams.get('page')) >= 1 ? parseInt(urlParams.get('page')) : 1;
    const searchStr = urlParams.get('search') || '';
    const pageSizeStr = parseInt(urlParams.get('size')) || 50;

    const [currentPage, setCurrentPage] = useState(page);
    const [search, setSearch] = useState(searchStr);
    const [tableData, setTableData] = useState([]);
    const [total, setTotal] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [pageSize, setPageSize] = useState(pageSizeStr);

    const { data: callbacksData, refetch } = fetchCallbacks(currentPage, pageSize, search);

    const Cell = ({ cell: { row: { original } } }) => (
        <div className="w-full py-2 h-full flex flex-col gap-1 justify-evenly">
            <CustomModal callback={original} />
            <DeleteOrderModal callback={original} />
        </div>
    );

    useEffect(() => {
        const updateUrl = () => {
            const url = new URL(location);
            url.searchParams.set('page', currentPage);
            url.searchParams.set('size', pageSize);
            url.searchParams.set('search', search);
            history.pushState({}, '', url);
        };
        updateUrl();
        refetch();
    }, [currentPage, search, history, location]);

    useEffect(() => {
        if (callbacksData?.data?.data) {
            setTableData(callbacksData.data.data.rows);
            setTotal(callbacksData.data.data.count);
        }
    }, [callbacksData]);

    const columns = useMemo(() => [
        ...Columns,
        {
            Header: 'Action',
            accessor: 'action',
            Cell,
        },
    ], []);

    return (
        <div className="p-7 h-full overflow-y-scroll bg-gray-50">
            <button 
                onClick={() => {
                    if (currentPage !== 1) {
                        setCurrentPage(1)
                    } else {
                        refetch();
                    }
                }} 
                className='flex gap-2 items-center justify-center bg-[#00A48B] py-[1rem] px-[1.5rem] rounded-lg my-[0.5rem]' 
            >
                <FontAwesomeIcon icon={faRefresh} color='#fff'  />
                <span className='text-[1.2rem] font-medium text-[#fff]' >Refresh</span>
            </button>
            <div className="flex gap-4 items-center">
                <input
                    className="px-6 w-full font-sans bg-slate-100 py-4"
                    placeholder={`${total} records...`}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <CallBackContext.Provider value={{ data: tableData, columns, refetch }}>
                {tableData.length > 0 && <TableLayout />}
            </CallBackContext.Provider>
            <div className="btn_div">
                <button disabled={currentPage <= 1} onClick={() => setCurrentPage(currentPage - 1)} className="previous">Previous</button>
                <span className="pageindex">Page {currentPage} of {Math.ceil(total / pageSize)}</span>
                <button disabled={currentPage >= Math.ceil(total / pageSize)} onClick={() => setCurrentPage(currentPage + 1)} className="next">Next</button>
            </div>
        </div>
    );
}

export default RequestCallbacks;

import Cookies from "js-cookie";
import React from "react";
import Avatar from "react-avatar";
import { useLocation } from "react-router-dom";
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import "./topbar.scss";
import { useModalMain } from "../../../../contexts/modal";
import { CreateOrder } from "../../../../components/modal-forms/create-order";
import MedicineForm from "../../../../components/MedicinePicker/MedicineForm";
import CreateUserWithPatient from "../../../../components/modal-forms/create-user/CreateUserWithPatient";
import { CreateUser } from "../../../../components/modal-forms/create-user";
import { AiOutlineUsergroupAdd, AiOutlineUser } from "react-icons/ai"
import { GiMedicines } from "react-icons/gi"
import { ImCart } from "react-icons/im"





function TopBar() {
    const [name, setName] = React.useState("John Doe");

    const [buttonLabels, setButtonLabels] = React.useState(["Create User"]);
    const history = useLocation();
    React.useEffect(() => {
        Cookies.get("user") && setName(JSON.parse(Cookies.get("user")).name);
    }, [Cookies.get("user")]);

    React.useEffect(() => {
        if (history.pathname.includes("orders")) {
            return setButtonLabels(["Create Order", "Create User", "Create user with patient"])
        }
        switch (history.pathname) {
            case "/medicine-master":
                return setButtonLabels(["Add New Medicine", "Create User", "Create user with patient"]);
            default:
                return setButtonLabels(["Create User", "Create user with patient"]);
        }
    }, [history.pathname]);

    function OpenModal(buttonLabel) {
        switch (buttonLabel) {
            case "Create Order":
                return CreateOrder;
            case "Add New Medicine":
                return MedicineForm;
            case "Create user with patient":
                return CreateUserWithPatient;
            case "Create User":
                return CreateUser;
        }
    }

    const { openModal } = useModalMain()
    return (
        <div className="topbar-container">
            <div className="px-4 topbar-greetings rounded-t-xl">
                <div className="flex flex-row gap-2">
                    {

                        buttonLabels.map((label) => {
                            return (
                                <button
                                    key={label}
                                    className="bg-elm-500 text-white-50 px-4 py-3 rounded-2xl font-sans text-md font-semibold  flex justify-center gap-2"
                                    onClick={() => openModal(OpenModal(label), "Add User", {}, false)}>
                                    {label}
                                    {
                                        label === 'Create user with patient' ?
                                            <AiOutlineUsergroupAdd className="text-2xl" />
                                            : label === 'Create User' ?
                                                <AiOutlineUser className="text-xl" />
                                                : label === 'Add New Medicine' ?
                                                    <GiMedicines className="text-xl" />
                                                    : label === "Create Order" ?
                                                        <ImCart className="text-xl" />
                                                        : null

                                    }
                                </button>
                            )
                        })
                    }
                </div>


                {/* Menu */}
                <Menu transition menuStyle={{ marginRight: "10px" }} menuButton={<MenuButton>
                    <div className="flex items-center gap-3">
                        <h1 className="font-bold font-sans">Hi, {name}</h1>
                        <Avatar
                            size="40"
                            facebook-id="invalidfacebookusername"
                            src="https://static.vecteezy.com/system/resources/previews/007/224/856/original/flat-doctor-avatar-for-website-chat-window-vector.jpg"
                            className="rounded-full"
                        />
                    </div>
                </MenuButton>}>
                    <MenuItem key="btn1">
                        <button onClick={() => {
                            Cookies.remove("user");
                            Cookies.remove("token");
                            Cookies.remove("refresh_token")
                            Cookies.remove("token_expires_at")
                            window.location.reload();
                        }} className="logoutbtn">Logout
                        </button>
                    </MenuItem>
                </Menu>
            </div>
        </div>
    );
}

export default TopBar;

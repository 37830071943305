export const DIGITIZE_ORDER_TYPE = "DIGITIZE"
export const PRESORTED_ORDER_TYPE = "PRESORTED_ORDERS"
export const TOKEN_EXPIRY_THRESHOLD = 100

export const TENANTS = {
    "dev-pillengine.pillup.com": "2eea1b63-6b1a-4b56-811a-df849d953c6d",
    "pillengine.pillup.com": "2eea1b63-6b1a-4b56-811a-df849d953c6d",

    "dev-pillengine.eazydose.in": "efdb62cf-5d22-4752-9c4f-faae13da1322",
    "pillengine.eazydose.in": "efdb62cf-5d22-4752-9c4f-faae13da1322"
};

export const TENANT_COMPANY_MAP = {
    "PILLUP": "2eea1b63-6b1a-4b56-811a-df849d953c6d",
    "EAZY": "efdb62cf-5d22-4752-9c4f-faae13da1322"
}
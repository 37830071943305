import React, { useState } from 'react'
import { getShortCode } from './helper'
import { toast } from 'react-toastify'
const Marketing = () => {
    const [formData, setFormData] = useState({
        message: '',
        medium: '',
        source: '',
        campaign: ''
    })
    const [generatedMessage, setGeneratedMessage] = useState('')
    const [copyStatus, setCopyStatus] = useState('')
    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const { message, medium, source, campaign } = formData
        if (!message || (!medium && !source && !campaign)) {
            toast.warn("Please fill all the fields.")
            return
        }

        const shortCodeResponse = await getShortCode({ medium, source, campaign });

        if (shortCodeResponse) {
            const finalMessage = `${message} ${`Invitation Code: ${shortCodeResponse.shortCode || ''}`}`
            setGeneratedMessage(finalMessage)
        } else {
            setGeneratedMessage('')
        }
    }

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(generatedMessage)
            setCopyStatus('Copied!')
            setTimeout(() => setCopyStatus(''), 2000)
        } catch (err) {
            setCopyStatus('Failed to copy')
        }
    }

    return (
        <div className="min-h-screen bg-white py-16">
            <div className='p-8'>
                <h1 className='text-2xl font-bold text-gray-900 mb-4'>Generate Template Message</h1>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className=' flex flex-col gap-2'>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                            Message <span className="text-red-500">*</span>
                        </label>
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            className="w-full"
                            rows="3"
                            placeholder="Enter your message here..."
                        />
                    </div>
                    <div className='flex justify-between gap-4 flex-wrap' >
                        <div className='flex-1 flex flex-col gap-1' >
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                Source
                            </label>
                            <select
                                name="source"
                                value={formData.source}
                                onChange={handleChange}
                                className="w-full"
                            >
                                <option value="">Select Source</option>
                                <option value="facebook">Facebook</option>
                            </select>
                        </div>
                        <div className='flex-1 flex flex-col gap-1' >
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                Medium
                            </label>
                            <input
                                type="text"
                                name="medium"
                                value={formData.medium}
                                onChange={handleChange}
                                className="w-full h-[40px]"
                                placeholder="Enter medium here..."
                            />
                        </div>
                        <div className='flex-1 flex flex-col gap-1' >
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                Campaign
                            </label>
                            <input
                                type="text"
                                name="campaign"
                                value={formData.campaign}
                                onChange={handleChange}
                                className="w-full h-[40px]"
                                placeholder="Enter campaign here..."
                            />
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="bg-[#00A48B] text-white-50 font-bold p-3 rounded-lg border-none hover:bg-[#00A48B]/80 transition-colors cursor-pointer"
                    >
                        Generate Message
                    </button>
                </form>
                {generatedMessage && <div>
                    <div className='flex items-center justify-between mb-2 mt-4'>
                        <h2 className="text-lg font-bold text-gray-800 mb-4">Generated Message</h2>
                        <div className='flex items-center gap-2'>
                            {copyStatus && <p className='text-sm text-green-500'>{copyStatus}</p>}
                            <button
                                onClick={handleCopy}
                                className="text-sm px-3 py-1 rounded hover:bg-[#00A48B]/80 outline-none border-none bg-[#00A48B] text-white-50"
                            >
                                Copy
                            </button>
                        </div>
                    </div>
                    <p>{generatedMessage}</p>
                </div>}
            </div>
        </div>
    )
}

export default Marketing
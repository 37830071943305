/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import TableLayout from './table/tableLayout';
import { Columns } from './table/constants';
import { DeliveryContext } from '../../contexts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { fetchDeliveries } from '../../hooks/fetch-hooks/get-all-deliveries/fetch-deliveries';
import { toast } from 'react-toastify';
import { request } from '../../config/helpers/axios-instance';

function Delivery() {

    const urlParams = new URLSearchParams(location.search);
    const page = parseInt(urlParams.get('page')) >= 1 ? parseInt(urlParams.get('page')) : 1;
    const searchStr = urlParams.get('search') || '';
    const pageSizeStr = parseInt(urlParams.get('size')) || 50;

    const [currentPage, setCurrentPage] = useState(page);
    const [search, setSearch] = useState(searchStr);
    const [tableData, setTableData] = useState([]);
    const [total, setTotal] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [pageSize, setPageSize] = useState(pageSizeStr);

    const [cancelLoading, setCancelLoading] = useState(false);

    const { data: deliveriesData, refetch } = fetchDeliveries(currentPage, pageSize, search);

    const cancelOrder = async (id) => {
        if (!id) {
            toast.warn("Invalid ID")
            return;
        }

        setCancelLoading(true);

        try {
            const response = await request({
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                url: `delivery/cancel/${id}`,
            });

            if (response.data && response.data.success) {
                toast.success("Order Cancelled");
                refetch();
            } else {
                toast.error("Error cancelling order");
            }
        } catch (error) {
            toast.error("Error cancelling order");
        }

        setCancelLoading(false);
    }

    const generateManifest = async (id) => {
        if (!id) {
            toast.warn("Invalid ID")
            return;
        }

        try {
            const response = await request({
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                url: `delivery/generate-manifest/${id}`,
            });

            if (response.data && response.data.success) {
                toast.success("Manifest Generated");
                window.open(response.data?.data?.manifestUrl, '_blank');
            } else {
                toast.error("Error generating manifest");
            }
        } catch (error) {
            toast.error("Error generating manifest");
        }
    }

    const generateLabel = async (id) => {
        if (!id) {
            toast.warn("Invalid ID")
            return;
        }

        try {
            const response = await request({
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                url: `delivery/generate-label/${id}`,
            });

            if (response.data && response.data.success) {
                toast.success("Label Generated");
                window.open(response.data?.data?.labelUrl, '_blank');
            } else {
                toast.error("Error generating label");
            }
        } catch (error) {
            toast.error("Error generating label");
        }
    }

    const Cell = ({ cell: { row: { original } } }) => (
        <div className="w-full py-2 h-full flex flex-col gap-1 justify-evenly" >
            {original.status !== 'CANCELLED' && <button className='bg-red-500 flex justify-center items-center text-[#fff] p-[0.5rem] rounded-[5px] font-semibold' onClick={async () => await cancelOrder(original.id)} >{cancelLoading ? "Cancelling" : "Cancel Order"}</button>}
            {original.status !== 'CANCELLED' && !original?.courierDetails?.manifestUrl && <button className='bg-red-500 flex justify-center items-center text-[#fff] p-[0.5rem] rounded-[5px] font-semibold' onClick={async () => await generateManifest(original.id)} >Generate Manifest</button>}
            {original?.courierDetails?.manifestUrl && <a className='bg-yellow-300 flex justify-center items-center text-[#fff] p-[0.5rem] rounded-[5px] font-semibold' href={original?.courierDetails?.manifestUrl} target={'_blank'} rel={'noreferrer'} >Download Manifest</a>}
            {original.status !== 'CANCELLED' && !original?.courierDetails?.labelUrl && <button className='bg-green-400 flex justify-center items-center text-[#fff] p-[0.5rem] rounded-[5px] font-semibold' onClick={async () => await generateLabel(original.id)} >Generate Label</button>}
            {original?.courierDetails?.labelUrl && <a className='bg-green-400 flex justify-center items-center text-[#fff] p-[0.5rem] rounded-[5px] font-semibold' href={original?.courierDetails?.labelUrl} target={'_blank'} rel={'noreferrer'} >Download Label</a>}
        </div>
    );

    useEffect(() => {
        const updateUrl = () => {
            const url = new URL(location);
            url.searchParams.set('page', currentPage);
            url.searchParams.set('size', pageSize);
            url.searchParams.set('search', search);
            history.pushState({}, '', url);
        };
        updateUrl();
        refetch();
    }, [currentPage, search, history, location]);

    useEffect(() => {
        if (deliveriesData?.data?.data) {
            setTableData(deliveriesData.data.data.rows);
            setTotal(deliveriesData.data.data.count);
        }
    }, [deliveriesData]);

    const columns = useMemo(() => [
        ...Columns,
        {
            Header: 'Action',
            accessor: 'action',
            Cell,
        },
    ], []);

    return (
        <div className="p-7 h-full overflow-y-scroll bg-gray-50">
            <button
                onClick={() => {
                    if (currentPage !== 1) {
                        setCurrentPage(1)
                    } else {
                        refetch();
                    }
                }}
                className='flex gap-2 items-center justify-center bg-[#00A48B] py-[1rem] px-[1.5rem] rounded-lg my-[0.5rem]'
            >
                <FontAwesomeIcon icon={faRefresh} color='#fff' />
                <span className='text-[1.2rem] font-medium text-[#fff]' >Refresh</span>
            </button>
            <div className="flex gap-4 items-center">
                <input
                    className="px-6 w-full font-sans bg-slate-100 py-4"
                    placeholder={`${total} records...`}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <DeliveryContext.Provider value={{ data: tableData, columns, refetch }}>
                {tableData.length > 0 && <TableLayout />}
            </DeliveryContext.Provider>
            <div className="btn_div">
                <button disabled={currentPage <= 1} onClick={() => setCurrentPage(currentPage - 1)} className="previous">Previous</button>
                <span className="pageindex">Page {currentPage} of {Math.ceil(total / pageSize)}</span>
                <button disabled={currentPage >= Math.ceil(total / pageSize)} onClick={() => setCurrentPage(currentPage + 1)} className="next">Next</button>
            </div>
        </div>
    );
}

export default Delivery;

import SHIPROCKET_LOGO from "../../assets/shiprocketLogo.webp";

const SHIPROCKET_DEFAULT_PICKUP_PINCODE = "201301";

const DELIVERY_PARTNER_IDS_MAP = {
    SHIPROCKET: 1,
}

const SHIPROCKET_DELIVERY_STEPS = {
    "DELIVERY_DETAILS": "delivery-details",
    "SELECT_COURIER": "select-courier",
}

const DEFAULT_PACKAGE_BOXES = {
    RECTANGULAR_BOX: {
        name: "rectangular-box",
        length: 25,
        breadth: 18,
        height: 10,
        weight: 0.9,
    },
    SQUARE_BOX: {
        name: "square-box",
        length: 18,
        breadth: 18,
        height: 10,
        weight: 0.648,
    },
    SMALL_BOX: {
        name: "small-box",
        length: 15,
        breadth: 15,
        height: 10,
        weight: 0.45,
    },
}

const DELIVERY_PARTNERS = {
    SHIPROCKET: {
        partnerName: "shiprocket",
        partnerLogo: SHIPROCKET_LOGO,
    },
}

export {
    DELIVERY_PARTNERS,
    DEFAULT_PACKAGE_BOXES,
    SHIPROCKET_DELIVERY_STEPS,
    DELIVERY_PARTNER_IDS_MAP,
    SHIPROCKET_DEFAULT_PICKUP_PINCODE
}
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import MutlipleAddresses from './multipleAddresses';

import React, { useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";

export default function MutlipleAddressPrint(props) {
    let componentRef = useRef();

    return (
        <div className="h-[85vh]">
            {/* button to trigger printing of target component */}
            <ReactToPrint
                trigger={() => <button id="print-button" className="bg-elm-500 text-white-50  px-4 py-3 rounded-2xl font-sans text-md font-semibold">Print</button>}
                content={() => componentRef.current}
            />
            <hr className="my-3" />
            <div ref={componentRef}>
                {MutlipleAddresses(props)}
            </div>
        </div>
    );
}

MutlipleAddressPrint.propTypes = {
    addresses: PropTypes.any
}
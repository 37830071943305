const LOGIN = {
    LOGHOME: "/login",
    REGISTER: "/register",
};
const RouteConstants = {

    HOME: "home",
    USERDETAILS: "/user-details/:id",
    // RECORDS LAYOUT
    EDIT_ORDER: "/order/edit/:orderId",
    USERS: "/users",
    USER_PAGE: "/user/:id",
    Medicine_Master: "/medicine-master",
    CURRENTORDERS: "/current-orders",
    FUTUREORDERS: "/future-orders",
    PASTORDERS: "/past-orders",
    ALLORDERS: "/all-orders",
    ORDERDETAILS: "/order-details/:id",
    BatchDetails: "/item-details/:id",

    // STATUS LAYOUT
    STATUS: "/status",

    QUALIFIED_LEAD: "qualified-lead",
    INVENTORY_CHECK: "inventory-check",
    CONFIRM_DIGITAL_RX: "confirm-digital-rx",
    CONFIRM_PAYMENT: "confirm-payment",
    OCS_FILE: "ocs-file",
    PACKAGING_AND_DISPENSER: "packaging-and-dispenser",
    PROCESSING_ORDER: "processing-order",
    SHIPPED: "shipped",
    UPLOAD_PRX: "upload-prx",

    // CALENDER LAYOUT
    CALENDER: "/calender",

    // TRANSACTIONS
    TRANSACTIONS: "/transactions",

    // INVOICES
    INVOICES: "/invoices",

    PRXPAGE: "/prx/:orderId",
    DRXPAGE: "/drx/:orderId",

    //REQUEST CALLBACK
    REQUEST_CALLBACK: "request-callbacks",


    //DIGITIZE Orders
    DIGITIZE_ORDERS: "digitize-orders",

    DELIVERY: "delivery",
    MARKETING: "marketing",
};

export { RouteConstants, LOGIN };

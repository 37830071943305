
import { request } from "../../../config/helpers/axios-instance";
import { useQuery } from "react-query";


const fetchCallbacks = (page, size, search) => {
    return useQuery(
        ["get-all-callbacks"],
        () =>
            request({
                url: `requestCallback/admin`,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                params: search.length > 0 ? {
                    page: page,
                    size: size,
                    patientName: search,
                    phoneNumber: search,
                } : {
                    page: page,
                    size: size,
                },
            })

    );
}



export { fetchCallbacks };





import React from "react";

export const renderNameColumn = (row) => {
    return <div className="flex flex-row gap-2 items-center">
        <h1>
            <b className="text-lg">
                {row?.userOrder?.mapUserPatient?.Patient?.name} :
                &nbsp;
            </b>
            <span className="text-elm-500 text-lg">
                {row?.userOrder?.mapUserPatient?.Patient?.contact_no}
            </span>
            {row?.userOrder?.mapUserPatient?.User?.name !=
                row?.userOrder?.mapUserPatient?.Patient?.name && (
                    <>
                        <b className="text-blue-500 text-lg">
                            &nbsp;{" "}
                            {[
                                row?.userOrder?.mapUserPatient?.relationship === "OLD"
                                    ? ""
                                    : row?.userOrder?.mapUserPatient?.relationship?.toLowerCase(),
                            ]}
                        </b>
                        <br />
                        <b className="text-lg">
                            {row?.userOrder?.mapUserPatient?.User?.name}:
                        </b>
                        &nbsp;
                        <span className="text-elm-500 text-lg">
                            {row?.userOrder?.mapUserPatient?.User?.contact_no}
                        </span>
                    </>
                )}
        </h1>
    </div >
}
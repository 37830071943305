import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BREAKFAST from "../../assets/morning.png";
import LUNCH from "../../assets/afternoon.png";
import EVENING from "../../assets/evening.png";
import DINNER from "../../assets/night.png";
import CUSTOM from "../../assets/custom_time.png";
import LOADER from "../../assets/loader.gif"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { request } from "../../config/helpers/axios-instance";
// import _ from "lodash";

export const timingConstants = {
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    dinner: "dinner",
    custom: "custom",
};

const defaultTimings = {
    [timingConstants.morning]: "08:00",
    [timingConstants.afternoon]: "14:00",
    [timingConstants.evening]: "18:00",
    [timingConstants.dinner]: "21:00",
    [timingConstants.custom]: "15:00",
};

const updateTimings = async (url, data) => {
    try {
        const res = await request({
            method: "PUT",
            url,
            data,
        });

        if (res && res.data.success) {
            return res.data;
        } else {
            toast.warning(res.data.error);
            return null;
        }
    } catch (error) {
        toast.error("Something went wrong");
        return null;
    }
};

const TimingInput = ({ label, value, onChange, icon }) => (
    <div className="flex flex-col gap-[5px]">
        <div className="flex flex-col items-center justify-center gap-[5px]">
            <img src={icon} className="h-[18px] object-contain" />
            <span className="text-[15px] font-regular">{label}</span>
        </div>
        <input
            key={label}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            type="time"
            className="form-input w-[7rem] block border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
    </div>
);

const UserTimingModal = ({ show, onClose, user }) => {

    const [loading, setLoading] = useState(false)

    const [timingsObj, setTimingsObj] = useState({
        [timingConstants.morning]: defaultTimings[timingConstants.morning],
        [timingConstants.afternoon]: defaultTimings[timingConstants.afternoon],
        [timingConstants.evening]: defaultTimings[timingConstants.evening],
        [timingConstants.dinner]: defaultTimings[timingConstants.dinner],
        [timingConstants.custom]: defaultTimings[timingConstants.custom],
    });

    const handleTimingObjChange = async (time, value) => {
        setTimingsObj((prevState) => {
            return {
                ...prevState,
                [time]: value,
            };
        });
    };

    const handleSaveTimings = async () => {
        setLoading(true)

        const data = {
            timings: {
                BREAKFAST: timingsObj[timingConstants.morning]
                    ? timingsObj[timingConstants.morning]
                    : undefined,
                LUNCH: timingsObj[timingConstants.afternoon]
                    ? timingsObj[timingConstants.afternoon]
                    : undefined,
                SNACK: timingsObj[timingConstants.evening]
                    ? timingsObj[timingConstants.evening]
                    : undefined,
                DINNER: timingsObj[timingConstants.dinner]
                    ? timingsObj[timingConstants.dinner]
                    : undefined,
                CUSTOM: timingsObj[timingConstants.custom]
                    ? [timingsObj[timingConstants.custom]]
                    : undefined,
            },
        };

        if (!user) {
            return toast.error("Invalid callback");
        }

        let url = `user/admin/id/${user?.mapUserPatient?.patientId}`;

        await updateTimings(url, data);
        setLoading(false)
    };

    useEffect(() => {
        if (show) {
            const userTimings = user?.mapUserPatient?.Patient?.userDetail?.timings

            if (userTimings) {
                setTimingsObj({
                    [timingConstants.morning]: userTimings["BREAKFAST"],
                    [timingConstants.afternoon]: userTimings["LUNCH"],
                    [timingConstants.evening]: userTimings["SNACK"],
                    [timingConstants.dinner]: userTimings["DINNER"],
                    [timingConstants.custom]: userTimings["CUSTOM"],
                });
            } else {
                setTimingsObj({
                    [timingConstants.morning]: defaultTimings[timingConstants.morning],
                    [timingConstants.afternoon]:
                        defaultTimings[timingConstants.afternoon],
                    [timingConstants.evening]: defaultTimings[timingConstants.evening],
                    [timingConstants.dinner]: defaultTimings[timingConstants.dinner],
                    [timingConstants.custom]: defaultTimings[timingConstants.custom],
                });
            }
        }
    }, [show]);

    return show ? (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white-50 p-6 rounded-md">
                <div className="flex items-center justify-between">
                    <span className="text-xl font-medium text-gray-900">Timings</span>
                    <button
                        onClick={() => onClose()}
                        className="inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                        <FontAwesomeIcon icon={faXmark} size={"xl"} />
                    </button>
                </div>
                <hr className="my-6" />
                <div>
                    <div className="flex flex-row justify-around flex-wrap gap-[30px] w-full">
                        <TimingInput
                            label={"Morning"}
                            icon={BREAKFAST}
                            value={timingsObj[timingConstants.morning]}
                            onChange={(value) =>
                                handleTimingObjChange(timingConstants.morning, value)
                            }
                        />
                        <TimingInput
                            label={"Lunch"}
                            icon={LUNCH}
                            value={timingsObj[timingConstants.afternoon]}
                            onChange={(value) =>
                                handleTimingObjChange(timingConstants.afternoon, value)
                            }
                        />
                        <TimingInput
                            label={"Evening"}
                            icon={EVENING}
                            value={timingsObj[timingConstants.evening]}
                            onChange={(value) =>
                                handleTimingObjChange(timingConstants.evening, value)
                            }
                        />
                        <TimingInput
                            label={"Dinner"}
                            icon={DINNER}
                            value={timingsObj[timingConstants.dinner]}
                            onChange={(value) =>
                                handleTimingObjChange(timingConstants.dinner, value)
                            }
                        />
                        <TimingInput
                            label={"Custom"}
                            icon={CUSTOM}
                            value={timingsObj[timingConstants.custom]}
                            onChange={(value) =>
                                handleTimingObjChange(timingConstants.custom, value)
                            }
                        />
                    </div>
                    <div className="mt-6 flex items-center justify-end">
                        <button
                            onClick={handleSaveTimings}
                            className="bg-blue-500 py-3 rounded-[5px] text-white-50 w-[9rem] h-[3rem] flex justify-center items-center"
                        >
                            {!loading ? "Save" : <img src={LOADER} className="w-[18px] object-contain" />}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

TimingInput.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    icon: PropTypes.string.isRequired,
};

UserTimingModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    user: PropTypes.any.isRequired,
    timings: PropTypes.exact({
        morning: PropTypes.string,
        afternoon: PropTypes.string,
        evening: PropTypes.string,
        dinner: PropTypes.string,
    }),
};

export default UserTimingModal;

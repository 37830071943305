import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types";
import { getServiceability, schedulePickup, validateCourierDetails } from '../../helper';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import moment from 'moment';
import { DELIVERY_PARTNER_IDS_MAP, SHIPROCKET_DEFAULT_PICKUP_PINCODE } from '../../constants';

const CourierDetailsForm = ({ backButton, deliveryData, setDeliveryData, userPatientAddresses, onContinue }) => {
    const [couriers, setCouriers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortBy, setSortBy] = useState('fastest');

    const setCourierId = (courierId) => {
        setDeliveryData((prevState) => {
            return {
                ...prevState,
                courierId: courierId,
            }
        })
    }

    const handleSchedulePickup = async () => {
        if (!validateCourierDetails(deliveryData)) {
            return;
        }

        setLoading(true);

        const scheduledDelivery = await schedulePickup(deliveryData);
        if (scheduledDelivery && scheduledDelivery.success) {
            onContinue();
        }

        setLoading(false);
    }

    const sortedCouriers = () => {
        if (sortBy === 'cheapest') {
            return couriers.sort((a, b) => a.rate - b.rate);
        } else if (sortBy === 'fastest') {
            return couriers.sort((a, b) => moment(a.etd).diff(moment(b.etd)));
        } else if (sortBy === 'rating') {
            return couriers.sort((a, b) => b.rating - a.rating);
        }
    }

    useEffect(() => {
        (async () => {
            if (deliveryData.shippingAddressId) {
                setCourierId('');
                const deliveryPincode = userPatientAddresses.find(address => address.id === deliveryData.shippingAddressId).pincode;
                if (deliveryPincode) {
                    const data = {
                        pickup: SHIPROCKET_DEFAULT_PICKUP_PINCODE,
                        delivery: deliveryPincode,
                        weight: Math.max(deliveryData.packageDetails.weight, (deliveryData.packageDetails.length * deliveryData.packageDetails.breadth * deliveryData.packageDetails.height) / 5000) || 0.9,
                        cod: 0,
                    }
                    const serviceabilityReposnse = await getServiceability(DELIVERY_PARTNER_IDS_MAP.SHIPROCKET, data);
                    if (serviceabilityReposnse) {
                        setCouriers(serviceabilityReposnse?.data?.available_courier_companies.sort((a, b) => a.rate - b.rate) || []);
                    }
                }
            }
        })()
    }, [deliveryData.shippingAddressId, userPatientAddresses, deliveryData.packageDetails])

    return (
        <div className='flex flex-col gap-[0.9rem]' >
            <div className='flex justify-start items-start' >
                {backButton()}
            </div>
            <div className='flex flex-col' >
                <div className='flex justify-between items-center mb-[1rem]' >
                    <label>Select courier</label>
                    <div >
                        <span className='mr-[0.5rem]' >Sort By:</span>
                        <select className='w-min' value={sortBy} onChange={(e) => setSortBy(e.target.value)} >
                            <option value={'fastest'} >Fastest</option>
                            <option value={'cheapest'} >Cheapest</option>
                            <option value={'rating'} >Best Rated</option>
                        </select>
                    </div>
                </div>
                <div className='flex flex-col gap-[1rem] h-[400px] overflow-scroll no-scrollbar' >
                    {
                        sortedCouriers().map((courier) => {
                            return (
                                <div onClick={() => setCourierId(courier.courier_company_id)} key={courier.courier_company_id} className={`cursor-pointer flex justify-between p-[1rem] rounded-[10px] border-[1px] ${deliveryData.courierId === courier.courier_company_id ? "border-[#28A48B] bg-[#eaf6f3]" : "border-[#fff] bg-gray-100"}`} >
                                    <div className='flex flex-col gap-[0.5rem] !items-start' >
                                        <span className='font-semibold text-[1.3rem]' >{courier.courier_name}</span>
                                        <div className='h-[4rem] w-[4rem]' >
                                            <CircularProgressbar styles={buildStyles({
                                                pathColor: "#28A48B",
                                                trailColor: "transparent",
                                                textColor: "#28A48B",
                                            })} value={courier.rating * 20} text={`${courier.rating.toFixed(1)}`} />
                                        </div>
                                    </div>
                                    <div className='flex flex-col gap-[0.5rem] items-start'>
                                        <span className='flex justify-between gap-[1rem] w-full' ><span className='font-semibold' >Pickup: </span>{courier.pickup_availability === "1" ? "Today" : "Tomorrow"}</span>
                                        <span className='flex justify-between gap-[1rem] w-full'><span className='font-semibold' >ETD: </span>{courier.etd}</span>
                                        <span className='flex justify-between gap-[1rem] w-full'><span className='font-semibold' >Charges: </span>₹{courier.rate}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className='flex justify-end' >
                <button onClick={handleSchedulePickup} disabled={loading} className='bg-[#28A48B] p-[1rem] rounded-[0.5rem] text-white-50 font-semibold' >{loading ? "Scheduling" : "Schedule"}</button>
            </div>
        </div>
    )
}

CourierDetailsForm.propTypes = {
    deliveryData: PropTypes.object.isRequired,
    setDeliveryData: PropTypes.func.isRequired,
    userPatientAddresses: PropTypes.object.isRequired,
    onContinue: PropTypes.func.isRequired,
    backButton: PropTypes.element.isRequired
};

export default CourierDetailsForm
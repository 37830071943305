import React, { useContext, useEffect, useState } from "react";
import moment from "moment"
import PropTypes from "prop-types";
import { OrderDetailsData } from "../../contexts";
import { useNavigate } from "react-router";
import "../../styles/Instance.scss";
import { formatDateTime } from "../../utils/DatetimeFormat-table";
// import AddressPrint from "../..components/address-print/addressPrint";
import { ORDERS_COLUMN, REORDERS_COLUMN, COLUMNS_FUCTIONALITIES, DIGITIZE_COLUMN } from "./Constants";
import { DownLoadTable } from "../../utils/DownloadtablesInExcel";
import TextareaAutosize from 'react-textarea-autosize';
import { updateFutureOrder } from "../../hooks/update-hooks/update-future-order";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { useAddModalMain } from "../../contexts/address-modal/AddModal";
import { isRowSelected, markAllAsDelivered, markAllAsShipInTransit, printMultipleAddress, renderNameColumn } from "./helpers";
import { PRESORTED_ORDER_TYPE } from "../../utils/constants";

function TableLayout({
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    changeRightBarOpen,
    state,
    setPageSort,
    refetchOrders
}) {

    const { setData, OrderType } = useContext(OrderDetailsData);
    const { sortBy } = state;
    // eslint-disable-next-line no-unused-vars
    const [selectedRows, setSelectedRows] = useState([])
    // eslint-disable-next-line no-unused-vars
    const { AddopenModal } = useAddModalMain();

    let Columns, TableData, ColumnHeaders, ExcelName;

    const selectRowsHandler = (selectAll, row) => {
        let preSelectedRows = [...selectedRows];
        if (selectAll) {
            if (preSelectedRows.length === rows.length) {
                preSelectedRows = []
            }
            else {
                for (let index = 0; index < rows.length; index++) {
                    const element = rows[index];
                    let includes = isRowSelected(element, preSelectedRows)
                    if (includes === null) {
                        preSelectedRows.push({ orderId: element.original.id, addressId: element.original.addressId })
                    }
                }
            }
        }
        else {
            let includes = isRowSelected(row, preSelectedRows);
            if (includes != null) {
                preSelectedRows.splice(includes, 1)
            }
            else {
                let selctingRow = { orderId: row.original.id, addressId: row.original.addressId };
                preSelectedRows.push(selctingRow);
            }
        }
        setSelectedRows(preSelectedRows)
    }




    switch (OrderType) {
        case PRESORTED_ORDER_TYPE:
            Columns = ORDERS_COLUMN
            ColumnHeaders = ['contact_no', ...Columns.filter((column) => { return column.Header != "#" }).map((header) => header.Header)];
            TableData = rows.map((row) => {
                return [row.original?.mapUserPatient?.User?.contact_no, row.original.id, row.original?.mapUserPatient?.User?.name, moment(row.original.endDate).format('Do MMM YYYY k:mm'), row.original.noOfDays, row.original?.partner?.name, row.original.daysLeft, row.original?.inventoryId]
            })
            ExcelName = 'All-Orders-data';
            break;
        case 'ReOrder':
            Columns = REORDERS_COLUMN
            ColumnHeaders = ['contact_no', ...Columns.filter((column) => { return column.Header != "#" }).map((header) => header.Header)];
            TableData = rows.map((row) => {
                return [row.original?.mapUserPatient?.User?.contact_no, row.original.id, row.original?.mapUserPatient?.User?.name, row.original?.address?.state, moment(row.original.endDate).format('Do MMM YYYY k:mm'), row.original.noOfDays, row.original.daysLeft, row.original.notes]
            })
            ExcelName = 'Re-Orders-data';

            break;
        case DIGITIZE_COLUMN:
            Columns = DIGITIZE_COLUMN
            ColumnHeaders = ['contact_no', ...Columns.filter((column) => { return column.Header != "#" }).map((header) => header.Header)];
            TableData = rows.map((row) => {
                return [row.original?.mapUserPatient?.User?.contact_no, row.original.id, row.original?.mapUserPatient?.User?.name, moment(row.original.endDate).format('Do MMM YYYY k:mm'), row.original.noOfDays, row.original?.partner?.name, row.original.daysLeft, row.original?.inventoryId]
            })
            ExcelName = 'Digitize-Orders-data';
            break
        default:
            Columns = ORDERS_COLUMN
            ColumnHeaders = ['contact_no', ...Columns.filter((column) => { return column.Header != "#" }).map((header) => header.Header)];
            TableData = rows.map((row) => {
                return [row.original?.mapUserPatient?.User?.contact_no, row.original.id, row.original?.mapUserPatient?.User?.name, moment(row.original.endDate).format('Do MMM YYYY k:mm'), row.original.noOfDays, row.original?.partner?.name, row.original.daysLeft, row.original?.inventoryId]
            })
            ExcelName = 'All-Orders-data';
            break;
    }
    useEffect(() => {
        setPageSort(sortBy);
    }, [sortBy]);

    const navigate = useNavigate();
    const onClick = (row) => {
        navigate(`/order-details/${row.original.id}`);
    };

    const updateComment = (value, id) => {
        try {
            updateFutureOrder({ 'notes': value }, id)
        } catch (error) {
            toast.error(error)
        }
    }



    const debounceOnChange = React.useCallback(debounce(updateComment, 200), []);

    return (
        <div className="relative">
            <div className="flex flex-row gap-5 relative ">

                {COLUMNS_FUCTIONALITIES[OrderType].includes("selectAllRows") &&
                    <div className="mt-4 text-black px-4 py-3 rounded-2xl font-sans text-md font-semibold  flex flex-row gap-2 items-center">
                        <input
                            type="checkbox"
                            onChange={() => {
                                selectRowsHandler(true, null)
                            }}
                            checked={selectedRows.length === rows.length ? true : false}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <p>{selectedRows.length === rows.length ? 'Unselect' : 'Select'} All rows</p>

                    </div>


                }
                {COLUMNS_FUCTIONALITIES[OrderType].includes("selectAllRows") && selectedRows.length > 0 && < button
                    id="button"
                    className="bg-black mt-4 text-white-50 px-4 py-3 rounded-2xl font-sans text-md font-semibold  flex justify-center"
                    onClick={() => markAllAsDelivered(selectedRows, rows, setSelectedRows, refetchOrders)}
                >
                    {`Mark as "DELIVERED"`}
                </button>}
                {COLUMNS_FUCTIONALITIES[OrderType].includes("selectAllRows") && selectedRows.length > 0 && < button
                    id="button"
                    className="bg-black mt-4 text-white-50 px-4 py-3 rounded-2xl font-sans text-md font-semibold  flex justify-center"
                    onClick={() => markAllAsShipInTransit(selectedRows, rows, setSelectedRows, refetchOrders)}
                >
                    {`Mark as "SHIPPED_IN_TRANSIT"`}
                </button>}
                {COLUMNS_FUCTIONALITIES[OrderType].includes("DownloadExcel") && < button className="bg-elm-500 mt-4 text-white-50 px-4 py-3 rounded-2xl font-sans text-md font-semibold  flex justify-center" onClick={() => DownLoadTable(OrderType, ExcelName, ColumnHeaders, TableData)}>
                    Download (Excel)
                </button>}
                {COLUMNS_FUCTIONALITIES[OrderType].includes("PrintMultipleAddress") && < button
                    id="button"
                    className="bg-elm-500 mt-4 text-white-50  px-4 py-3 rounded-2xl font-sans text-md font-semibold  flex justify-center absolute right-0"
                    onClick={() => printMultipleAddress(selectedRows, AddopenModal)}
                >
                    Print Multiple Address
                </button>}
            </div>
            <div className="block py-4  bg-[white] rounded-2xl shadow-soft-xl mt-4  dark:border-gray-700 dark:hover:bg-gray-700">
                <table
                    key="all-orders"
                    className="table-auto w-[100%] "
                    {...getTableProps()}
                >
                    <thead>
                        {headerGroups?.map((headerGroup, id) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                key={id}
                                className="text-left h-16 font-sans outline-1 border-b-2 border-gray-100"
                            >
                                {headerGroup.headers.map((column, id) => (
                                    <>
                                        {column.Header === "Name" ||
                                            column.Header === "Patient" ||
                                            column.Header === "Patient Phone" ||
                                            column.Header === "Phone" ||
                                            column.Header === "Action" ||
                                            column.Header === "Status" ? (
                                            <th
                                                className="pl-3 font-sans text-left px-2"
                                                key={id}
                                                {...column.getHeaderProps()}
                                            >
                                                {column.render("Header")}
                                            </th>
                                        ) : (
                                            <th
                                                className="pl-3 font-sans text-left"
                                                key={id}
                                                {...column.getHeaderProps(
                                                    column.getSortByToggleProps()
                                                )}
                                            >
                                                {column.render("Header")}
                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? " ▼"
                                                            : " ▲"
                                                        : ""}
                                                </span>
                                            </th>
                                        )}
                                    </>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className=" border-collapse ">
                        {rows?.map((row, rowIndex) => {
                            prepareRow(row);
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    className={`text-left border-t border-gray-100 h-14 pl-3 font-sans ${row?.original?.status === "SHIPPED_ERROR" && "bg-[#ff9966]"} ${row?.original?.prescription?.links?.length > 0 && (row?.original?.prescription?.links[0] !== 'not' && row?.original?.prescription?.links[0] !== 'oldOrder') ? " hover:bg-slate-200 " : " hover:bg-[#dc143c] text-white-50 bg-[#dc143c]"}`}
                                    key={rowIndex}
                                >
                                    {row.cells.map((cell, cellIndex) => {
                                        return (
                                            <React.Fragment key={cellIndex}>
                                                {cell.column.Header === "ID" ? (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        className={"pl-3 py-4 text-left font-sans underline hover:text-black font-medium cursor-pointer text-elm-500"}
                                                        onClick={() => {
                                                            onClick(row);
                                                            changeRightBarOpen();
                                                            setData(row.original);
                                                        }}
                                                        key={cellIndex}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                ) : cell.column.Header === "#" ? (
                                                    <td
                                                        key={cellIndex}
                                                        {...cell.getCellProps()}
                                                        className="pl-3 py-4 text-left font-sans"
                                                    >
                                                        <div className="flex items-center">
                                                            <input
                                                                id={row.original.id}
                                                                type="checkbox"
                                                                onChange={() => {
                                                                    selectRowsHandler(false, row);
                                                                }}
                                                                checked={isRowSelected(row, selectedRows) !== null ? true : false}
                                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                            />
                                                        </div>
                                                    </td>
                                                ) : cell.column.Header === "Name" ? (
                                                    <td
                                                        key={cellIndex}
                                                        {...cell.getCellProps()}
                                                        onClick={() => {
                                                            // onClick(row);
                                                            changeRightBarOpen();
                                                            setData(row.original);
                                                        }}
                                                        className="pl-3 py-4 text-left text-lg font-sans font-size-xl"
                                                    >
                                                        {renderNameColumn(row)}
                                                    </td>
                                                ) : cell.column.Header === "DaysLeft" ? (
                                                    <td
                                                        key={cellIndex}
                                                        {...cell.getCellProps()}
                                                        onClick={() => {
                                                            // onClick(row);
                                                            changeRightBarOpen();
                                                            setData(row.original);
                                                        }}
                                                        className="pl-3 py-4 text-left font-sans"
                                                    >
                                                        {cell.value.split("T")[0]}
                                                    </td>
                                                ) : cell.column.Header === "EndDate" ? (
                                                    <td
                                                        key={cellIndex}
                                                        {...cell.getCellProps()}
                                                        onClick={() => {
                                                            // onClick(row);
                                                            changeRightBarOpen();
                                                            setData(row.original);
                                                        }}
                                                        className="pl-3 py-4 text-left font-sans"
                                                    >
                                                        {formatDateTime(row.original.endDate)}
                                                    </td>
                                                ) : cell.column.Header === "Action" ? (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        className="pl-3 py-4 text-left  font-sans"
                                                        key={cellIndex}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                ) : cell.column.Header === "Status" ? (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        className="pl-3 py-4 w-fit text-left flex items-center font-sans"
                                                        key={cellIndex}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                ) : cell.column.Header === "Note" ? (
                                                    <td
                                                        key={cellIndex}
                                                        {...cell.getCellProps()}
                                                        className="py-4 pl-3 font-sans text-left"
                                                    >
                                                        <TextareaAutosize minRows={1} maxRows={4} className="border-gray-200 border  text-left w-full rounded-md p-2  focus:outline-none" defaultValue={row.original.notes} onChange={(e) => { debounceOnChange(e.target.value, row.original.id) }}></TextareaAutosize>
                                                    </td>)
                                                    :
                                                    (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            onClick={() => {
                                                                setData(row.original);
                                                                changeRightBarOpen();
                                                            }}
                                                            className="pl-3 py-4 text-left  font-sans"
                                                            key={cellIndex}
                                                        >
                                                            {cell.render("Cell")}
                                                        </td>
                                                    )}
                                            </React.Fragment>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div >
    );
}

export default React.memo(TableLayout);

TableLayout.propTypes = {
    getTableProps: PropTypes.any,
    getTableBodyProps: PropTypes.func.isRequired,
    headerGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    prepareRow: PropTypes.func.isRequired,
    visibleColumns: PropTypes.arrayOf(PropTypes.object),
    changeRightBarOpen: PropTypes.func.isRequired,
    state: PropTypes.objectOf(PropTypes.any),
    pageOptions: PropTypes.any,
    pageCount: PropTypes.number,
    setPageIndex: PropTypes.func,
    Index: PropTypes.number,
    setPageSort: PropTypes.func,
    nextPage: PropTypes.any,
    previousPage: PropTypes.any,
    canNextPage: PropTypes.any,
    canPreviousPage: PropTypes.any,
    SelectRow: PropTypes.any,
    SelectedRows: PropTypes.any,
    selectAllRowsHandler: PropTypes.any,
    refetchOrders: PropTypes.any
};

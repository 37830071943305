import { request } from '../../config/helpers/axios-instance'
import { toast } from 'react-toastify'

export const getShortCode = async (data) => {
    let requestData = {
        data,
        "isPublic": true,
        "type": "UTM_PARAMETERS"
    }

    try {
        const response = await request({
            url: `shortCodeMapping/`,
            method: 'POST',
            data: requestData
        })
        if (response.data && response.data.success) {
            toast.success("Short code generated successfully.")
            return response.data.data;
        }
        toast.warn("Couldn't generate short code.")
        return null;
    } catch (error) {
        toast.warn("Couldn't generate short code.")
        return null;
    }
}
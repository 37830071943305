import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { DELIVERY_PARTNER_IDS_MAP, SHIPROCKET_DELIVERY_STEPS } from './constants';
import { getUserPatientAddresses } from './helper';
import DeliveryDetailsForm from './components/shiprocketForm/DeliveryDetailsForm';
import CourierDetailsForm from './components/shiprocketForm/CourierDetailsForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const BackButton = ({ onClick }) => {
    return (
        <button onClick={onClick} className='cursor-pointer flex items-center justify-start gap-[0.5rem]' >
            <FontAwesomeIcon icon={faChevronLeft} size='3x' className='w-[0.7rem] object-contain text-black' />
            <span className='text-[1.2rem]' >Back</span>
        </button>
    )
}

BackButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

const initialDeliveryData = {
    "orderId": "",
    "billingAddressId": "",
    "shippingAddressId": "",
    "packageDetails": {
        "name": "Medicines",
        "sku": "Medicines",
        "units": 1,
        "sellingPrice": 999,
        "length": 25,
        "breadth": 18,
        "height": 10,
        "weight": 0.9,
        "subTotal": 999
    },
    "courierId": "",
    "deliveryPartnerId": DELIVERY_PARTNER_IDS_MAP.SHIPROCKET,
}


const DeliveryCreateModal = ({ orderId, mapUserPatient, address, onClose }) => {
    const [userPatientAddresses, setUserPatientAddresses] = useState([address]);
    const [deliveryData, setDeliveryData] = useState(initialDeliveryData);
    const [currentStep, setCurrentStep] = useState(SHIPROCKET_DELIVERY_STEPS.DELIVERY_DETAILS);

    // const setDeliveryPartner = (partner) => {
    //     setDeliveryData((prevState) => {
    //         return {
    //             ...prevState,
    //             deliveryPartner: partner,
    //         }
    //     })
    // }

    useEffect(() => {
        (async () => {
            if (mapUserPatient) {
                const res = await getUserPatientAddresses(mapUserPatient.id);
                if (res.length > 0) {
                    setUserPatientAddresses(res);
                }
            }
        })()
    }, [mapUserPatient]);

    useEffect(() => {
        setDeliveryData((prevState) => {
            return {
                ...prevState,
                orderId: orderId,
                billingAddressId: address.id,
                shippingAddressId: address.id,
            }
        });
    }, [])

    return (
        <div className='flex flex-col gap-[0.9rem]' >
            <div className='flex justify-between' >
                <span className='text-[1.5rem] font-bold' >Create Delivery</span>
                <span className='text-[1.2rem]' >Order ID: <span className='text-[1.2rem] font-semibold' >{`#${orderId}`}</span></span>
            </div>
            <hr />
            <div className='flex flex-col gap-[0.9rem]' >
                {/* <div className='flex flex-col' >
                    <label>Select delivery partner</label>
                    <select className='w-full' value={deliveryData.deliveryPartner} onChange={(e) => setDeliveryPartner(e.target.value)} >
                        {
                            Object.keys(DELIVERY_PARTNERS).map((key) => {
                                return (
                                    <option key={key} value={key} >
                                        {DELIVERY_PARTNERS[key].partnerName}
                                    </option>
                                )
                            })
                        }
                    </select>
                </div> */}
                {
                    currentStep === SHIPROCKET_DELIVERY_STEPS.DELIVERY_DETAILS && <DeliveryDetailsForm deliveryData={deliveryData} setDeliveryData={setDeliveryData} userPatientAddresses={userPatientAddresses} onContinue={() => setCurrentStep(SHIPROCKET_DELIVERY_STEPS.SELECT_COURIER)} />
                }
                {
                    currentStep === SHIPROCKET_DELIVERY_STEPS.SELECT_COURIER && <CourierDetailsForm backButton={() => <BackButton onClick={() => setCurrentStep(SHIPROCKET_DELIVERY_STEPS.DELIVERY_DETAILS)} />} deliveryData={deliveryData} setDeliveryData={setDeliveryData} userPatientAddresses={userPatientAddresses} onContinue={onClose} />
                }
            </div>
        </div >
    )
}

DeliveryCreateModal.propTypes = {
    orderId: PropTypes.number.isRequired,
    mapUserPatient: PropTypes.object.isRequired,
    address: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default DeliveryCreateModal
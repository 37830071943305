import axios from "axios";
import Cookies from "js-cookie";
import { getApiURL } from "./baseURL";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import { TOKEN_EXPIRY_THRESHOLD } from "../../utils/constants";

const cookies = Cookies.withConverter({
    read: function (value, name) {
        if (name === 'token') {
            return value
        }
        return Cookies.converter.read(value, name)
    },
    write: function (value, name) {
        if (name === 'token') {
            return value
        } else {
            return value
        }
    }
})

const client = axios.create({
    baseURL: getApiURL()
});

const getNewToken = async (authToken, refreshToken) => {
    try {
        const res = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_BACKEND_URL}user/verify`,
            headers: {
                "app-version": "100.0",
                "Content-Type": "application/json",
                "Authorization": authToken,
                "x-refresh-token": refreshToken,
                "ngrok-skip-browser-warning": "100.0"
            }
        })

        if (res.status === 200 && res.data) {
            return res.headers["x-access-token"]
        }

        return null
    } catch (error) {
        return null
    }
}


export const request = async ({ ...options }) => {

    const checkRefresh = Object.prototype.hasOwnProperty.call(options, 'checkRefresh') ? options['checkRefresh'] : true

    const onSuccess = (response) => {
        return response;
    };

    const onError = (error) => {

        if (error?.response?.status === 401) {
            cookies.remove('token');
            cookies.remove('user');
            cookies.remove('refresh_token')
            cookies.remove("token_expires_at")
            window.location.replace('/login')
        }
        else {
            window.location.replace('/404')
        }
        return error;
    };

    client.defaults.headers['app-version'] = '100.0'
    client.defaults.headers['ngrok-skip-browser-warning'] = '100.0'
    client.defaults.headers['x-tenant-id'] = Cookies.get("tenantId");

    if (checkRefresh && cookies.get("token_expires_at")) {
        const authTokenExpiresAt = moment(parseInt(cookies.get("token_expires_at")))

        const diff = authTokenExpiresAt.diff(moment(), "seconds")

        if (diff <= TOKEN_EXPIRY_THRESHOLD && cookies.get("token") && cookies.get("refresh_token")) {
            const token = await getNewToken(`${cookies.get("token")}`, `${cookies.get("refresh_token")}`)

            if (token) {
                const decoded = jwtDecode(token?.split(':')[0])
                const expiresAt = decoded?.expiresAt

                cookies.set("token", token, { expires: 1 });

                decoded.mappings = []

                if (decoded) {
                    cookies.set("user", JSON.stringify(decoded), { expires: 365 });
                    cookies.set("token_expires_at", expiresAt, { expires: 1 });
                }
            }
        }
    }

    client.defaults.headers.Authorization = `${cookies.get("token")}`

    if (!options?.headers?.['Content-Type']) {
        client.defaults.headers["Content-Type"] = "application/json"
    }

    client.defaults.headers = {
        ...client.defaults.headers,
        ...options?.headers
    }

    return client(options).then(onSuccess).catch(onError);
};
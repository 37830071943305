import React, { useRef, useEffect } from 'react'
import { request } from "../../../config/helpers/axios-instance";
import { toast } from 'react-toastify';
const Contexts = require("../../../contexts");
import "../../../styles/Signup.scss";


function SignInForm() {
    const { authState, authDispatch } = React.useContext(Contexts.Auth);
    const ref = useRef()
    const onSubmit = async (e) => {
        e.preventDefault()
        const response = await request({
            url: `user/loginOtp`,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                username: authState.contact_no,
            },
            checkRefresh: false
        });

        if (response.status === 200 && response.data.error === "") {
            authDispatch({ ...authState, step: 1 });
            toast.success("Success, Please Provide OTP send to your Phone");
        } else {
            toast.warn(response.data.error);
        }
    };
    useEffect(() => {
        ref.current.focus()
    }, [])
    return (
        <>
            <div className="flex flex-col w-96 items-center justify-center gap-10 px-6 py-8 mx-auto md:h-screen lg:py-0">
                <h1 className="font-sans text-6xl font-bold mb-4">
                    PillEngine
                </h1>
                <div className="w-full bg-white-50 rounded-2xl shadow-soft-xl  ">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl font-sans">
                            Sign in to your account
                        </h1>
                        <form onSubmit={(e) => onSubmit(e)}>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                    Your Mobile
                                </label>
                                <input
                                    type="contact_no"
                                    name="contact_no"
                                    id="contact_no"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-2xl focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 focus:outline-0"
                                    placeholder="Enter Your Number Here"
                                    maxLength={10}
                                    ref={ref}
                                    required={true}
                                    value={authState.contact_no}
                                    onChange={(e) => {
                                        if (!isNaN(e.target.value)) {
                                            authDispatch({ ...authState, contact_no: e.target.value })
                                        }
                                    }
                                    }
                                />

                            </div>

                            <button
                                type="submit"
                                id="signup"
                                className="w-full font-sans text-white-50bg-green-600 hover:bg-green-700t hover:text-white focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-2xl text-sm px-5 py-2.5 text-left mt-3">
                                Sign in
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export { SignInForm };
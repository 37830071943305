/* eslint-disable react/prop-types */
import { Modal } from "flowbite-react";
import React from "react";

import { GrDocumentPdf, GrDownload } from "react-icons/gr";
import UserTimingModal from "../user-timing-modal/UserTimingModal";

function PrescriptionModal ({ prescription, user }) {
    const [modalToggle, setModal] = React.useState(false);
    const [timingModal, setTimingModal] = React.useState(false);

    return <React.Fragment >
        <div className="flex flex-col gap-[3px]" >
            <button onClick={() => {
                setModal(true)
            }} className="bg-elm-500 text-white-50 p-1 rounded-md font-sans text-[12px] font-semibold" >
                View
            </button>
            <button onClick={() => {
                setTimingModal(true)
            }} className="bg-blue-500 text-white-50 p-1 rounded-md font-sans text-[12px] font-semibold" >
                Timings
            </button>
        </div>

        <UserTimingModal show={timingModal} onClose={setTimingModal} user={user} />
        
        {modalToggle && < Modal style={{ width: '100vw' }}
            className="w-full flex justify-center"
            onClick={() => { setModal(false) }}
            show={modalToggle}
            size={'100vw'}
            onClose={() => {
                setModal(false)
            }}>
            <Modal.Header style={{ backgroundColor: "white", width: "36vw", marginLeft: "32vw" }}>
                Prescriptions
            </Modal.Header>
            <Modal.Body
                style={{ backgroundColor: "white", width: "36vw", marginLeft: "32vw" }}
                className="flex justify-center"
            >
                {
                    modalToggle ?

                        <div style={{
                            height: "80vh", display: 'flex', flexDirection: 'column', gap: '20px', paddingBottom: '100px'
                        }}>

                            {

                                prescription.length > 0 ?
                                    prescription.map(link => {
                                        return <div key={link} className="h-[100%]">
                                            {
                                                link.includes('.pdf') ?
                                                    <div className="h-[100px] flex flex-col justify-center items-center">
                                                        <a href={link} className="p-4 border text-2xl h-[80%] border-gray-300 rounded-lg flex flex-row gap-5 items-center">
                                                            <GrDocumentPdf className="text-4xl" />
                                                            {link.split('/')[3]}
                                                            <GrDownload className="text-4xl" />
                                                        </a>
                                                    </div>
                                                    :
                                                    < img key={link} src={link} style={{ height: '100%', objectFit: 'contain' }} />
                                            }
                                        </div>
                                    }
                                    )

                                    : "No Prescription Found !"
                            }
                        </div> : null}
            </Modal.Body>

        </Modal>
        }
    </React.Fragment>
}

export { PrescriptionModal };
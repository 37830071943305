import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";


const OCSCreateModal = ({ handleGenerateOcs, orderData }) => {
    const [patientName, setPatientName] = useState()

    useEffect(() => {
        setPatientName(orderData?.mapUserPatient?.Patient?.name)
    }, [orderData])

    return (
        <div className='flex flex-col gap-[0.9rem]' >
            <div className='flex justify-between' >
                <span className='text-[1.5rem] font-bold' >Create OCS</span>
            </div>
            <div className='flex flex-col gap-[0.9rem]' >
                <span className='text-[1.2rem]' >Order ID: <span className='text-[1.2rem] font-semibold' >{`#${orderData.id}`}</span></span>
            </div>
            <div className='flex flex-col gap-[0.9rem]' >
                <input type="text" className='p-4 focus:outline-none placeholder:opacity-50  h-10 border-gray-200 border w-full' placeholder='Patient Name' value={patientName} onChange={(e) => setPatientName(e.target.value)} />
            </div>
            <div className='flex flex-col gap-[0.9rem]' >
                <button className='bg-[#158D7B] text-white p-4 rounded-md text-white-50' onClick={() => handleGenerateOcs(orderData, patientName)} >Generate OCS</button>
            </div>
        </div >
    )
}

OCSCreateModal.propTypes = {
    handleGenerateOcs: PropTypes.func.isRequired,
    orderData: PropTypes.object.isRequired,
};

export default OCSCreateModal
import { toast } from "react-toastify";
import { request } from "../../config/helpers/axios-instance";
import { DELIVERY_PARTNER_IDS_MAP } from "./constants";

const getUserPatientAddresses = async (mapUserPatientId) => {
    try {
        const response = await request({
            url: `address/mapUserPatient/${mapUserPatientId}`,
            method: 'GET',
        })

        if (response.data && response.data.success) {
            return response.data?.data?.rows;
        }
    } catch (error) {
        toast.warn("Couldn't get addresses.")
    }

    return [];
}

const getServiceability = async (deliveryPartnerId, data) => {
    try {
        switch (deliveryPartnerId) {
            case (DELIVERY_PARTNER_IDS_MAP.SHIPROCKET): {
                const { pickup, delivery, weight, cod } = data;
                if (!delivery || !pickup || (cod !== 0 && cod !== 1) || !weight) {
                    toast.warn("Invalid parameters.");
                    return null;
                }

                const response = await request({
                    url: `delivery/serviceability?pickup=${pickup}&delivery=${delivery}&weight=${weight}&cod=${cod}&deliveryPartnerId=${deliveryPartnerId}`,
                    method: 'GET',
                })
                if (response.data && response.data.success) {
                    return response.data?.data;
                }
                break;
            }
            default: {
                toast.warn("Couldn't get serviceability.")
                return null;
            }
        }
        return null;
    } catch (error) {
        toast.warn("Couldn't get serviceability.")
        return null;
    }
}

const validateDeliveryDetails = (data) => {
    if (!data) {
        toast.warn("Please fill all the fields.");
        return false;
    }

    const requiredFields = [
        'orderId',
        'billingAddressId',
        'shippingAddressId',
        'deliveryPartnerId'
    ];

    for (const field of requiredFields) {
        if (!data[field]) {
            toast.warn(`Please fill ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}`);
            return false;
        }
    }

    // Validate package details
    if (!data.packageDetails) {
        toast.warn("Package details are required");
        return false;
    }

    const requiredPackageFields = [
        'name',
        'sku',
        'units',
        'sellingPrice',
        'length',
        'breadth',
        'height',
        'weight',
        'subTotal'
    ];

    for (const field of requiredPackageFields) {
        if (!data.packageDetails[field] && data.packageDetails[field] !== 0) {
            toast.warn(`Please fill package ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}`);
            return false;
        }
    }

    // Validate numeric fields
    const numericFields = ['units', 'sellingPrice', 'length', 'breadth', 'height', 'weight', 'subTotal'];
    for (const field of numericFields) {
        if (isNaN(data.packageDetails[field]) || data.packageDetails[field] <= 0) {
            toast.warn(`Invalid value for package ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}`);
            return false;
        }
    }

    return true;
}

const validateCourierDetails = (data) => {
    if (!data) {
        toast.warn("Please fill all the fields.");
        return false;
    }

    const requiredFields = [
        'courierId',
    ];

    for (const field of requiredFields) {
        if (!data[field]) {
            toast.warn(`Please fill ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}`);
            return false;
        }
    }

    return true;
}

const schedulePickup = async (data) => {
    try {
        const response = await request({
            url: `delivery/`,
            method: 'POST',
            data
        })
        if (response.data && response.data.success) {
            toast.success("Pickup scheduled successfully.")
            return response.data;
        }
        toast.warn("Couldn't schedule pickup.")
        return null;
    } catch (error) {
        toast.warn("Couldn't schedule pickup.")
        return null;
    }
}

export {
    getUserPatientAddresses,
    getServiceability,
    validateCourierDetails,
    validateDeliveryDetails,
    schedulePickup,
}
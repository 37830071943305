import React, { useContext } from "react";
import { useTable } from "react-table";
import PropTypes from "prop-types";
import { formatDateTime } from "../../../utils/DatetimeFormat-table";
import { DeliveryContext } from "../../../contexts";
import { DELIVERY_PARTNER_IDS_MAP } from "../../../components/delivery/constants";
import { renderNameColumn } from "./helper";


export default function TableLayout() {
    const { data, columns } = useContext(DeliveryContext);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    })

    function renderCellComponent(cell) {
        const { Header } = cell.column;
        const { original } = cell.row;

        switch (Header) {
            case "Name":
                return <td className="px-2 text-left w-fit" key={`${original.id}_name`}>{renderNameColumn(original)}</td>;
            case "Delivery Partner":
                return <td className="px-2 text-left w-fit" key={`${original.id}_delivery_partner`}><a className="underline text-elm-500" href={`https://app.shiprocket.in/seller/orders/details/${original.deliveryPartnerUniqueId}`} target={"_blank"} rel={"noreferrer"} >{Object.keys(DELIVERY_PARTNER_IDS_MAP).find((key) => DELIVERY_PARTNER_IDS_MAP[key] === parseInt(original.deliveryPartnerId))}</a></td>;
            case "Pickup Date":
                return <td className="px-2 text-left w-fit" key={`${original.id}_pickup_date`}>{formatDateTime(original.pickupDate)}</td>;
            case "User Order ID":
                return <td className="px-2 text-left w-fit" key={`${original.id}_user_order_id`}>{<a className="underline text-elm-500" href={`/order/edit/${original.userOrderId}?orderType=PRESORTED_ORDERS`} target={"_blank"} rel={"noreferrer"} >{original.userOrderId}</a>}</td>;
            case "#":
                return (
                    <td
                        {...cell.getCellProps()}
                        className="py-4 text-left pl-3 font-sans cursor-pointer hover:text-black text-elm-500 font-medium"
                        key={original.id}>
                        {cell.render("Cell")}
                    </td>
                )
            default:
                return <td className="px-2 text-left" key={`${original.id}_${cell.getCellProps().key}`} {...cell.getCellProps()}> {cell.render("Cell")}</td >;
        }
    }
    return (

        <div className="block bg-white rounded-2xl shadow-soft-xl w-[100%] bg-[white] mt-4 p-2 px-4  dark:hover:bg-gray-700">
            <table {...getTableProps()} className="table-auto w-[100%] font-sans">
                <thead className="text-center border-b-2 border-gray-50 h-16">
                    {headerGroups.map((headerGroup, id) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={id} className="text-left border-b-2 border-gray-100">

                            {headerGroup.headers.map((column, id) => (
                                <th {...column.getHeaderProps()} key={id} className="font-sans text-left pl-3">{column.render("Header")}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, id) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={id} className="hover:bg-white px-2 border-b text-left border-gray-50 pl-3 font-sans">
                                {row.cells.map((cell, id) => {
                                    return <React.Fragment key={id}>
                                        {renderCellComponent(cell)}
                                    </React.Fragment>
                                })}

                            </tr>
                        );
                    })}
                </tbody>
            </table >
        </div>
    )

}
TableLayout.propTypes = {
    columns: PropTypes.any,
    data: PropTypes.any,
    refetch: PropTypes.any


};





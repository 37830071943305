import React, { useState, useEffect } from 'react'
import _ from 'lodash';
import PropTypes from "prop-types";
import { DEFAULT_PACKAGE_BOXES } from '../../constants';
import { validateDeliveryDetails } from '../../helper';

const DeliveryDetailsForm = ({ deliveryData, setDeliveryData, userPatientAddresses, onContinue }) => {
    const [shippingIsBilling, setShippingIsBilling] = useState(true);
    const [packageBox, setPacketBox] = useState(Object.keys(DEFAULT_PACKAGE_BOXES)[0]);

    const setBillingAddress = (addressId) => {
        if (shippingIsBilling) {
            setDeliveryData((prevState) => {
                return {
                    ...prevState,
                    billingAddressId: addressId,
                    shippingAddressId: addressId,
                }
            })
        } else {
            setDeliveryData((prevState) => {
                return {
                    ...prevState,
                    billingAddressId: addressId,
                }
            })
        }
    }

    const setShippingAddress = (addressId) => {
        setDeliveryData((prevState) => {
            return {
                ...prevState,
                shippingAddressId: addressId,
            }
        })
    }

    const setDimenstions = (dimension, value) => {
        if (dimension !== 'length' && dimension !== 'breadth' && dimension !== 'height') {
            return;
        }

        setDeliveryData((prevState) => {
            return {
                ...prevState,
                packageDetails: {
                    ...prevState.packageDetails,
                    [dimension]: value,
                }
            }
        })
    }

    const setWeight = (weight) => {
        setDeliveryData((prevState) => {
            return {
                ...prevState,
                packageDetails: {
                    ...prevState.packageDetails,
                    weight: weight,
                }
            }
        })
    }

    const setUnits = (units) => {
        setDeliveryData((prevState) => {
            return {
                ...prevState,
                packageDetails: {
                    ...prevState.packageDetails,
                    units: units,
                    subTotal: units * prevState.packageDetails.sellingPrice,
                }
            }
        })
    }

    const setSellingPrice = (price) => {
        setDeliveryData((prevState) => {
            return {
                ...prevState,
                packageDetails: {
                    ...prevState.packageDetails,
                    sellingPrice: price,
                    subTotal: price * prevState.packageDetails.units,
                }
            }
        })
    }
    const handleConfirmDeliveryDetails = () => {
        if (!validateDeliveryDetails(deliveryData)) {
            return;
        }
        onContinue();
    }

    useEffect(() => {
        setDeliveryData((prevState) => {
            return {
                ...prevState,
                packageDetails: {
                    ...prevState.packageDetails,
                    length: DEFAULT_PACKAGE_BOXES[packageBox].length,
                    breadth: DEFAULT_PACKAGE_BOXES[packageBox].breadth,
                    height: DEFAULT_PACKAGE_BOXES[packageBox].height,
                    weight: DEFAULT_PACKAGE_BOXES[packageBox].weight,
                }
            }
        });
    }, [packageBox])

    useEffect(() => {
        if (shippingIsBilling) {
            setDeliveryData((prevState) => {
                return {
                    ...prevState,
                    shippingAddressId: prevState.billingAddressId,
                }
            })
        }
    }, [shippingIsBilling])

    return (
        <div className='flex flex-col gap-[0.9rem]' >
            <div className='flex flex-col' >
                <label>Select Billing Address</label>
                <select className='w-full' value={deliveryData.billingAddressId} onChange={(e) => setBillingAddress(e.target.value)} >
                    {
                        userPatientAddresses && userPatientAddresses.map((userPatientAddress) => {
                            return (
                                <option key={userPatientAddress.id} value={userPatientAddress.id} >
                                    <div>
                                        <span><span>{userPatientAddress.name}</span> | <span>{userPatientAddress.phone}</span> | {`${userPatientAddress.line}, ${userPatientAddress.district}, ${userPatientAddress.state}, ${userPatientAddress.pincode}`}</span>
                                    </div>
                                </option>
                            )
                        })
                    }
                </select>
            </div>
            <div className='flex gap-[0.5rem] items-center' >
                <input id="shipping-is-billing-input" type={'checkbox'} className='m-0 p-0' checked={shippingIsBilling} onChange={() => setShippingIsBilling(!shippingIsBilling)} />
                <label className='m-0 p-0' htmlFor="hipping-is-billing-input" >Shipping is billing</label>
            </div>
            {
                !shippingIsBilling && <div className='flex flex-col' >
                    <label>Select Shipping Address</label>
                    <select className='w-full' value={deliveryData.shippingAddressId} onChange={(e) => setShippingAddress(e.target.value)} >
                        {
                            userPatientAddresses && userPatientAddresses.map((userPatientAddress) => {
                                return (
                                    <option key={userPatientAddress.id} value={userPatientAddress.id} >
                                        <div>
                                            <span><span>{userPatientAddress.name}</span> | <span>{userPatientAddress.phone}</span> | {`${userPatientAddress.line}, ${userPatientAddress.district}, ${userPatientAddress.state}, ${userPatientAddress.pincode}`}</span>
                                        </div>
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>
            }
            <div className='flex flex-col' >
                <label>Package</label>
                <select className='w-full' value={packageBox} onChange={(e) => setPacketBox(e.target.value)}  >
                    {
                        Object.keys(DEFAULT_PACKAGE_BOXES).map((key) => {
                            return (
                                <option key={DEFAULT_PACKAGE_BOXES[key].name} value={key} >
                                    <span>{`${_.capitalize(DEFAULT_PACKAGE_BOXES[key].name.split("-").join(" "))} | ${DEFAULT_PACKAGE_BOXES[key].length}cm X ${DEFAULT_PACKAGE_BOXES[key].breadth}cm X ${DEFAULT_PACKAGE_BOXES[key].height}cm`}</span>
                                </option>
                            )
                        })
                    }
                </select>
            </div>
            <div>
                <label>Package Dimensions ( in cm )</label>
                <div className='flex items-center justify-between' >
                    <div className='flex flex-col' >
                        <span>Length</span>
                        <input type='number' min={0.5} value={deliveryData.packageDetails.length} onChange={(e) => setDimenstions('length', e.target.value)} />
                    </div>
                    <span>X</span>
                    <div className='flex flex-col' >
                        <span>Breadth</span>
                        <input type='number' min={0.5} value={deliveryData.packageDetails.breadth} onChange={(e) => setDimenstions('breadth', e.target.value)} />
                    </div>
                    <span>X</span>
                    <div className='flex flex-col' >
                        <span>Height</span>
                        <input type='number' min={0.5} value={deliveryData.packageDetails.height} onChange={(e) => setDimenstions('height', e.target.value)} />
                    </div>
                </div>
            </div>
            <div className='flex gap-[0.9rem]' >
                <div className='flex flex-col flex-1' >
                    <label>Dead Weight(in kg)</label>
                    <input type='number' min={0} value={deliveryData.packageDetails.weight} onChange={(e) => setWeight(e.target.value)} />
                </div>
                <div className='flex flex-col flex-1' >
                    <label className='!text-gray-400' >Volumetric Weight(in kg)</label>
                    <input type='number' disabled className='!border-gray-400' value={(deliveryData.packageDetails.length * deliveryData.packageDetails.breadth * deliveryData.packageDetails.height) / 5000 || 0} />
                </div>
            </div>
            <div className='flex gap-[0.9rem]' >
                <div className='flex flex-col flex-1' >
                    <label>Units</label>
                    <input type='number' min={1} value={deliveryData.packageDetails.units} onChange={(e) => setUnits(e.target.value)} />
                </div>
                <div className='flex flex-col flex-1' >
                    <label>Selling Price</label>
                    <input type='number' min={0} value={deliveryData.packageDetails.sellingPrice} onChange={(e) => setSellingPrice(e.target.value)} />
                </div>
            </div>
            <div className='flex justify-end' >
                <button onClick={handleConfirmDeliveryDetails} className='bg-[#28A48B] p-[1rem] rounded-[0.5rem] text-white-50 font-semibold' >Select Courier</button>
            </div>
        </div>
    )
}

DeliveryDetailsForm.propTypes = {
    deliveryData: PropTypes.object.isRequired,
    setDeliveryData: PropTypes.func.isRequired,
    userPatientAddresses: PropTypes.object.isRequired,
    onContinue: PropTypes.func.isRequired,
};

export default DeliveryDetailsForm
import React, { useState } from 'react'
import { Formik, Field, ErrorMessage, Form } from "formik"
import { schema, initial, TypeOptions, ShapeOptions } from './Supplies';
import classes from "../../styles/MedicineForm.module.scss";
import { toast } from 'react-toastify';
import { request } from '../../config/helpers/axios-instance';
import { useModalMain } from '../../contexts/modal';
import Cookies from "js-cookie";
import { TENANTS } from '../../utils/constants';

function MedicineForm() {
    const tenantId = Cookies.get("tenantId");

    const [search, setSearch] = useState(undefined)
    const [data, setData] = useState([]);
    const { closeModal } = useModalMain()
    const [medId, setMedid] = useState('')

    const handleSubmit = async (values, { resetForm }) => {
        try {
            //updating states
            values["name"] = search
            values["inventoryId"] = medId
            let response = await request({ method: "POST", url: "/item/admin", data: values });
            if (response.data.success) {
                toast.success("Success")
                resetForm({ values: '' })
                closeModal()
            }
            if (!response.data.success) {
                toast.error(response.data.error || "Something went wrong")
            }
        }

        catch (err) {
            toast.error("Wrong data")
        }
    }


    const handleSearch = (event) => {
        setSearch(event.target.value)
        SearchRequest(event.target.value)
    }


    async function SearchRequest(value) {
        try {
            const body = {
                name: value
            }
            const searchvalues = await request({
                method: "POST",
                url: "eVitalRX/search",
                data: body
            })
            const rows = searchvalues.data.data.result
            if (rows) {
                rows.length > 0 ? setData(rows) : setData(searchvalues.data.data.did_you_mean_result)
            }
        }
        catch (error) {
            console.log(error)
        }
    }


    const handleChanges = (setFieldValue, val) => {
        setFieldValue('manufacture', val.manufacturer_name)
        setFieldValue('size', val.size)
        setFieldValue('mrp', val.mrp);
        setFieldValue('genericName', val.content)
    }

    return (
        <>
            <div>
                <h1 className='font-bold text-3xl text-black' style={{ margin: "15px 10px" }}>Add Medicine Form</h1>
                <Formik initialValues={initial} schema={schema} onSubmit={handleSubmit}>
                    {
                        ({ setFieldValue }) => {
                            return (
                                <Form className='my-3'>
                                    <div className='flex flex-col justify-center items-center my-3'>
                                        <div className='flex flex-col justify-evenly mx-3 my-2 w-[100%]'>
                                            <label htmlFor='name'>Commercial Name</label>
                                            <input type="text" name="name" className={classes.inputSearch} value={search} onChange={handleSearch} required={true} style={{ width: "100%", borderRadius: "7px" }} />
                                            {data.length > 0 && search.length > 3 && (
                                                <>
                                                    <div className={classes.Dropdown}>
                                                        <ul>
                                                            <li className='cursor-pointer' onClick={() => { setSearch(search), setData([]) }}>Add New</li>
                                                            {data.length > 0 && data.map((val) => {
                                                                return (
                                                                    <li key={val.medicine_id} className='cursor-pointer' onClick={() => { setSearch(val.medicine_name), setData([]), setMedid(val.medicine_id), handleChanges(setFieldValue, val) }}>{val.medicine_name}</li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                </>
                                            )}
                                            <ErrorMessage name="name" />
                                        </div>
                                        <div className='flex flex-col justify-evenly mx-3 w-[100%] my-2'>
                                            <label htmlFor='manufacture'>Manufacturer</label>
                                            <Field name="manufacture" className={classes.input} required={true} style={{ width: "100%" }} />
                                            <ErrorMessage name="manufacture" />
                                        </div>
                                        {
                                            tenantId && parseInt(tenantId) === TENANTS.eazyengine && (
                                                <div className='flex flex-col justify-evenly mx-3 w-[100%] my-2'>
                                                    <label htmlFor='manufacture'>Mnemonics Name</label>
                                                    <Field name="mnemonic" className={classes.input} required={true} style={{ width: "100%" }} />
                                                    <ErrorMessage name="mnemonic" />
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className='flex justify-center items-center my-3'>
                                        <div className='flex flex-col justify-evenly mx-3'>
                                            <label htmlFor='genericName'>Generic Name</label>
                                            <Field name="genericName" className={classes.input} required={true} />
                                            <ErrorMessage name="genericName" />
                                        </div>
                                        <div className='flex flex-col justify-evenly mx-3'>
                                            <label htmlFor='shape'>Shape</label>
                                            <Field as="select" name="shape" required={true} className="rounded-xl">
                                                {ShapeOptions.map((val) => {
                                                    return <option key={val} value={val}>{val}</option>
                                                })}
                                            </Field>
                                            <ErrorMessage name="shape" />
                                        </div>
                                    </div>
                                    <div className='flex justify-center items-center my-3'>
                                        <div className='flex flex-col justify-evenly mx-3'>
                                            <label htmlFor='color'>Color</label>
                                            <Field name="color" className={classes.input} />
                                            <ErrorMessage name="color" />
                                        </div>
                                        <div className='flex flex-col justify-evenly mx-3'>
                                            <label htmlFor='size'>Size</label>
                                            <Field type="number" name="size" className={classes.inputNum} required={true} />
                                            <ErrorMessage name="size" />
                                        </div>
                                    </div>
                                    <div className='flex justify-evenly items-center my-3 w-full'>
                                        <div className='flex flex-col justify-evenly mx-3 w-full'>
                                            <label htmlFor='medicineType'>Type</label>
                                            <Field as="select" name="medicineType" required={true} className="rounded-xl w-full">
                                                {TypeOptions.map((val) => {
                                                    return <option key={val} value={val}>{val}</option>
                                                })}
                                            </Field>
                                            <ErrorMessage name="medicineType" />
                                        </div>
                                    </div>
                                    <div className={classes.Btndiv}>
                                        <button type='submit'>Submit</button>
                                    </div>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </div>
        </>
    )
}


export default MedicineForm;
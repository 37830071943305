/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { genders, relationships, source } from './Constants'
import { useRef } from 'react';
import { useState } from 'react';
import { request } from '../../../config/helpers/axios-instance';
import { toast } from 'react-toastify';
import { useModalMain } from '../../../contexts/modal';
import { isObject } from 'lodash';
import moment from 'moment/moment';
import { GetPartners } from '../../../hooks/fetch-hooks/partners/get-partners';

export default function CreateUserWithPatient() {
    const [userImage, setUserImage] = useState(null)
    const [patientImage, setPatientImage] = useState(null)
    const [forPatient, setForPatient] = useState(false);
    const [createdUserId, setCreatedUserId] = useState(null);
    const [partner, setPartner] = useState('')
    const [partners, setPartners] = useState([]);
    const [PatientGender, setPatientGender] = useState('M');

    const [userSource, setSource] = useState('Whatsapp')
    const { data: Partners, refetch } = GetPartners(GetPartnerName(userSource), partner)



    let UserRef = useRef({});
    let PatientRef = useRef({});
    let { closeModal } = useModalMain()

    useEffect(() => {
        UserRef.current['name'].focus()
    }, [])

    useEffect(() => {
        if (Partners) {
            setPartners(Partners?.data?.data?.rows?.map((item) => {
                return { label: item.name, id: item.id };
            }))
        }

    }, [Partners])

    useEffect(() => {
        if (userSource === "Doctor" || userSource === "B2B") {
            refetch()
        }
    }, [partner, userSource])


    function GetPartnerName(partnerType) {
        switch (partnerType) {
            case "Doctor":
                return "DOCTOR";
            case "B2B":
                return "BUSINESS";
            default:
                return "DOCTOR"
        }
    }


    function createUser(values) {
        return request({
            method: "POST",
            url: "user/admin",
            data: values
        })
    }
    async function UploadImage(image, type) {
        let formData = new FormData();
        formData.append('image', image);
        let OriginalImage = await request({
            url: "/image/upload",
            method: 'POST',
            data: formData,
        })
        if (OriginalImage?.data?.success) {
            toast.success('Image Uploaded!');
            type === 'User' ? setUserImage(OriginalImage?.data?.data?.image) : setPatientImage(OriginalImage?.data?.data?.image);
        }
        else {
            toast.error('Image Not Uploaded!')
        }
    }


    function handlePartnerSearch(e) {
        setPartner(e.target.value)
    }


    async function AddUser(e) {
        e.preventDefault();
        let ApiResponseUser, values = {}
        if (!createdUserId) {
            let flag = false;
            Object.keys(UserRef.current).map((key) => {
                if (UserRef.current[key].value === '') {
                    flag = true;
                }
            })
            if (flag === true || userSource === '') {
                toast.warn('Fill All The Fields! (User)')
                return
            }
            values = {
                name: UserRef.current['name'].value,
                contact_no: UserRef.current['contact_no'].value,
                gender: UserRef.current['gender'].value,
                dob: UserRef.current['dob'].value,
                source: userSource,
                image: userImage,
                partnerId: parseInt(partner.id)
            }
            ApiResponseUser = await createUser(values);
            setCreatedUserId(ApiResponseUser?.data?.data?.userId);
        }

        if (ApiResponseUser?.data?.success || createdUserId) {
            if (forPatient) {
                let flag = false;
                Object.keys(PatientRef.current).map((key) => {
                    if (PatientRef.current[key].value === '') {
                        flag = true
                    }
                })
                if (flag === true) {
                    toast.warn('Fill All The Fields! (Patient)')
                    return
                }

                values = {
                    name: PatientRef.current['name'].value,
                    contact_no: PatientRef.current['contact_no'].value,
                    gender: PatientGender,
                    dob: PatientRef.current['dob'].value,
                    image: patientImage,
                    userId: createdUserId ? createdUserId : ApiResponseUser?.data?.data?.userId,
                    relationship: PatientRef.current['relationship'].value,
                    source: userSource,
                    partnerId: parseInt(partner.id)
                }
                let ApiResponsePatient = await createUser(values);
                if (ApiResponsePatient?.data?.success) {
                    toast.success('User created with patient!');
                    closeModal()
                }
                else {
                    toast.error(!isObject(ApiResponsePatient?.data?.error) ? ApiResponsePatient?.data?.error + 'On creating patient' : 'Error While creating Patient!')
                }
            }
            else {
                toast.success('User created!');
                closeModal()
            }

        }
        else {
            setCreatedUserId(null);
            toast.error(!isObject(ApiResponseUser?.data?.error) ? ApiResponseUser?.data?.error + ' On creating User' : 'Error while creating User !')
            return
        }

    }


    return (
        <div className='p-5'>
            <p className='text-left text-4xl font-semibold mb-7 '>Create User</p>
            <form className='flex flex-col gap-5' onSubmit={(e) => AddUser(e)}>
                {!createdUserId ?
                    <div className='flex flex-col gap-5'>
                        <div className='flex flex-row flex-1 gap-3'>
                            <div className='flex flex-col flex-auto'>
                                <label>Name</label>
                                <input ref={el => UserRef.current['name'] = el} className='p-2.5 border border-gray-300 rounded-xl ' placeholder='Enter  name'></input>
                            </div>
                            <div className='flex flex-col flex-auto'>
                                <label>Phone</label>
                                <input type='tel' ref={el => UserRef.current['contact_no'] = el} maxLength={10} onChange={(e) => {
                                    if (isNaN(e.target.value)) {
                                        UserRef.current['contact_no'].value = e.target.value.substring(0, e.target.value.length - 1)
                                    }
                                }} className='p-2.5 border border-gray-300 rounded-xl ' placeholder='Enter  contact_no number'></input>
                            </div>

                        </div>
                        <div className='flex flex-row flex-1 w-full gap-3'>
                            <div className='flex flex-col flex-auto'>
                                <label>Age</label>
                                <input ref={el => UserRef.current['age'] = el} type='tel' maxLength={4} onChange={(e) => {
                                    UserRef.current['age'].value = e.target.value
                                    UserRef.current['dob'].value = moment().subtract(e.target.value, 'years').format('YYYY-01-01')
                                }} className='p-2.5 border border-gray-300 rounded-xl ' placeholder='Enter Age in years'></input>
                            </div>
                            <div className='flex flex-col flex-auto'>
                                <label>DOB</label>
                                <input ref={el => UserRef.current['dob'] = el} className='p-2.5 border border-gray-300 rounded-xl ' placeholder='Enter dob' type='date' onChange={(e) => {
                                    UserRef.current['age'].value = isNaN(moment().diff(moment(e.target.value), 'years')) ? 0 : moment().diff(moment(e.target.value), 'years');
                                }}></input>
                            </div>
                            <div className='flex flex-col flex-auto '>
                                <label>Gender</label>
                                <select className='rounded-xl w-full' ref={el => UserRef.current['gender'] = el}>
                                    {
                                        genders.map((gender) => {
                                            return (<option value={gender.label === 'Select Gender' ? '' : gender.label} key={gender.label}>{gender.label}</option>)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className='flex flex-row w-full flex-1 gap-3 items-center'>
                            <div className='flex flex-col flex-auto w-[50%]'>
                                <label>Source</label>
                                <select className='rounded-xl w-full' value={userSource} onChange={(e) => setSource(e.target.value)}>
                                    {
                                        source.map((s) => {
                                            return (
                                                <option key={s.label} value={s.label === 'Select Source' ? "" : s.label}>{s.label}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            {userSource === "Doctor" || userSource === "B2B" ?
                                <div className="flex flex-col  flex-auto w-[50%]" >
                                    <label>{userSource}</label>
                                    <input type="text" name="partner" autoComplete='off' className='p-2.5 border border-gray-300 rounded-xl w-full  ' value={partner.label} onChange={(e) => handlePartnerSearch(e)} />
                                    {(partners.length > 0 && partner) && (
                                        <>
                                            <div className=" bg-slate-50 mt-1">
                                                <ul>
                                                    {partners.length > 0 && partners.map((val) => {
                                                        return (
                                                            <li key={val.id} className='cursor-pointer p-2 hover:bg-gray-100' onClick={() => setPartner(val)}>{val.label}</li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </>
                                    )}
                                </div>
                                : null}
                        </div>
                    </div>
                    :
                    <div>
                        User Created now create its patient
                    </div>
                }


                <div className='flex flex-row gap-2'>
                    <input className='p-2.5 border text-elm-500 focus:ring-elm-500 border-gray-300 rounded-xl   ' checked={forPatient} onChange={() => { setForPatient(!forPatient) }} type='checkbox'></input>
                    <label>Ordering For Patient</label>
                </div>
                {forPatient && <div className='flex flex-col gap-5 mt-10 animate-[opacity_1s_forwards] '>
                    <div className='flex flex-row gap-3'>
                        <div className='flex flex-col'>
                            <label>Patients Name</label>
                            <input ref={el => PatientRef.current['name'] = el} className='p-2.5 border border-gray-300 rounded-xl ' placeholder='Enter patients name' ></input>
                        </div>
                        <div className='flex flex-col flex-auto'>
                            <label>Patient Phone</label>
                            <input type='tel' ref={el => PatientRef.current['contact_no'] = el}
                                maxLength={10}
                                onChange={(e) => {
                                    if (isNaN(e.target.value)) {
                                        PatientRef.current['contact_no'].value = e.target.value.substring(0, e.target.value.length - 1)
                                    }
                                }}

                                className='p-2.5 border border-gray-300 rounded-xl ' placeholder='Enter patient contact_no'></input>
                        </div>
                    </div>
                    <div className='flex flex-row w-full gap-3'>
                        <div className='flex flex-col flex-auto'>
                            <label>Age</label>
                            <input ref={el => PatientRef.current['age'] = el} type='tel' maxLength={4} onChange={(e) => {
                                PatientRef.current['age'].value = e.target.value
                                PatientRef.current['dob'].value = moment().subtract(e.target.value, 'years').format('YYYY-01-01')
                            }} className='p-2.5 border border-gray-300 rounded-xl ' placeholder='Enter Age in years'></input>
                        </div>

                        <div className='flex flex-col flex-auto w-[33%]'>
                            <label>Patients DOB</label>
                            <input ref={el => PatientRef.current['dob'] = el} className='p-2.5 border border-gray-300 rounded-xl ' placeholder='Enter dob' type='date' onChange={(e) => {
                                PatientRef.current['age'].value = isNaN(moment().diff(moment(e.target.value), 'years')) ? 0 : moment().diff(moment(e.target.value), 'years');
                            }}></input>
                        </div>

                    </div>
                    <div className='flex flex-row w-full gap-3'>
                        <div className='flex flex-col flex-auto w-[33%]'>
                            <label>Patients Gender</label>
                            <select onChange={(e) => setPatientGender(e.target.value)} value={PatientGender} className='rounded-lg w-full'>
                                {
                                    genders.map((gender) => {
                                        return (
                                            <option key={gender.label} value={gender.label}>{gender.label}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='flex flex-col flex-auto w-[33%]'>
                            <label>Relationship</label>
                            <select ref={el => PatientRef.current['relationship'] = el} className='rounded-lg w-full'>
                                {PatientGender === 'M' ?
                                    relationships.map((relation) => {
                                        if (relation.label !== "SELF" && (relation.gender === 'Male' || relation.gender === '')) {
                                            return <option key={relation.label} value={relation.label}>{relation.label}</option>
                                        }
                                    })
                                    : PatientGender === 'F' ? relationships.map((relation) => {
                                        if (relation.label !== "SELF" && (relation.gender === 'Female' || relation.gender === '')) {
                                            return <option key={relation.label} value={relation.label}>{relation.label}</option>
                                        }
                                    }) :
                                        relationships.map((relation) => {
                                            return relation.label !== "SELF" && <option key={relation.label} value={relation.label}>{relation.label}</option>
                                        })
                                }
                            </select>
                        </div>
                    </div>
                </div>}
                <div className='flex flex-row flex-1 gap-5 mt-5'>
                    {!createdUserId && <div className='flex flex-col flex-auto w-[50%] gap-2'> <label>Users Image</label>
                        <input type="file"
                            id="avatar" name="avatar"
                            onChange={(e) => { UploadImage(e.target.files[0], 'User') }}
                            accept="image/png, image/jpeg" className='flex-1 p-2.5 border border-gray-300 rounded-xl ' />
                    </div>}
                    {forPatient &&
                        <div className='flex flex-col gap-2 w-[50%] animate-[opacity_1s_forwards] '>
                            <label>Patients Image</label>
                            <input onChange={(e) => { UploadImage(e.target.files[0]), 'Patient' }} type='file' className=' w-full p-2.5 border border-gray-300 rounded-xl ' />
                        </div>
                    }
                </div>
                <button type='submit' className='p-2.5 bg-elm-500 text-[white] rounded-lg'> Add User</button>
            </form>
        </div>
    )
}

const Columns = [
    {
        Header: "#",
        accessor: "id",
    },
    {
        Header: "Name",
        accessor: "name",
    },
    {
        Header: "User Order ID",
        accessor: "userOrderId",
    },
    {
        Header: "Delivery Partner ID",
        accessor: "deliveryPartnerUniqueId",
    },
    {
        Header: "Delivery Partner",
        accessor: "deliveryPartner",
    },
    {
        Header: "Status",
        accessor: "status",
    },
    {
        Header: "Pickup Date",
        accessor: "pickupDate",
    },
];
export { Columns };
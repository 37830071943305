/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Modal, Card } from "flowbite-react";
import React, { useEffect } from "react";
import { request } from "../../../config/helpers/axios-instance";


const PrescriptionModal = ({ updatePrescription, prescription, onClose, show, onlyShow }) => {
    const handleAddFile = async (e) => {
        const { files } = e.target;
        let formData = new FormData();
        for (let i in files) {
            formData.append('files', files[i]);
        }
        let resUploadImage = await request({ url: "/file/upload/prescription/", method: "POST", data: formData, headers: { "Content-Type": "multipart/form-data" } })

        if (resUploadImage && resUploadImage.status === 200 && resUploadImage.data.success) {
            const uploadedImage = resUploadImage?.data?.data;
            const newImages = [...prescription.links];
            uploadedImage.forEach((item) => {
                if (!newImages.includes(item.image || item.pdf)) {
                    newImages.push(item.image || item.pdf);
                }
            })
            await request({ url: `/prescription/admin/${prescription.id}`, method: "PUT", data: { links: newImages }, headers: { "Content-Type": "application/json" } });
            updatePrescription(newImages)
        }
    }

    const handleDeleteImage = async (image) => {
        let newImages = prescription.links.filter((item) => item !== image);
        await request({ url: `/prescription/admin/${prescription.id}`, method: "PUT", data: { links: newImages } })
        updatePrescription(newImages)
    }

    return (
        <React.Fragment >
            <Modal show={show} onClose={() => { onClose() }} dismissible>
                <Modal.Header className='bg-[white]' >
                    Prescriptions
                </Modal.Header>
                <Modal.Body className="bg-[white] overflow-y-scroll h-[80vh]">
                    <div className="flex flex-col gap-5" >
                        {
                            prescription?.links?.length > 0 ?

                                prescription?.links?.map(link => {
                                    if (link.endsWith(".pdf")) {
                                        return <div key={link} className="flex flex-col gap-2" >
                                            <div className="flex flex-row justify-between items-center" >
                                                <p>{link}</p>
                                                <a className="px-2 py-1 bg-[green]/[0.5] rounded-md" href={link} >Download</a>
                                            </div>
                                            {!onlyShow && <button onClick={() => handleDeleteImage(link)} className="w-full rounded-sm bg-[red]/[0.4] hover:bg-[red]/[0.6]" >Delete</button>}
                                            <hr />
                                        </div>
                                    } else {
                                        return <div key={link} className="flex flex-col gap-2" >
                                            <Card imgSrc={link} />
                                            <button onClick={() => handleDeleteImage(link)} className="w-full rounded-sm bg-[red]/[0.4] hover:bg-[red]/[0.6]" >Delete</button>
                                            <hr />
                                        </div>
                                    }
                                }
                                )
                                : "No Prescription Found !"
                        }
                        {!onlyShow && <input type='file' multiple onChange={handleAddFile} />}
                    </div>
                </Modal.Body>

            </Modal>

        </React.Fragment>
    )
}

export default PrescriptionModal;
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { GetPatientAddressById } from "../../../hooks/fetch-hooks/get-patient-address-by-id/get-patient-address-by-Userid";
import { Modal, TextInput, Label, } from "flowbite-react";
import { request } from "../../../config/helpers/axios-instance";
import { toast } from "react-toastify";
import moment from "moment/moment";
import CreateAddress from "../../../components/modal-forms/create-address";
import { CustomModalForm } from "../../../components/modal-forms";
import useModal from "../../../hooks/custom-hooks/useModal";

function CustomModal({ callback }) {
    const { isShowing: isCreateFormShowing, toggle: toggleCreateForm } = useModal();
    const [modalToggle, setModal] = React.useState(false);
    const [isAddressEmpty, setIsAddressEmpty] = useState(false);
    const [selectedAddress, setselectedAddress] = React.useState(0);
    const drNameRef = useRef();
    const prescriptionDateRef = useRef();
    const startDateRef = useRef();
    const noOfDaysRef = useRef();
    const [addresses, setAddress] = React.useState([]);

    useEffect(() => {
        if (modalToggle) {
            (async () => {
                try {
                    const response = await GetPatientAddressById(callback?.mapUserPatient?.id);
                    setAddress(response.data.data);
                    response.data.data.length == 0 ? setIsAddressEmpty(true) : setIsAddressEmpty(false);
                    drNameRef.current.value = callback?.additionalDetails?.doctorName || ""
                } catch (error) {
                    console.log(error)
                }
            })()
        }


    }, [modalToggle])

    const newOrder = async () => {
        let order = {
            "id": parseInt(callback.id),
            "drName": drNameRef.current.value,
            "prescriptionDate": prescriptionDateRef.current.value,
            "patientId": callback.mapUserPatient.Patient.id,
            "startDate": startDateRef.current.value,
            "noOfDays": parseInt(noOfDaysRef.current.value),
            "addressId": selectedAddress,
            "address": addresses.find(address => address.id == selectedAddress),
            "requestCallbackId": parseInt(callback.id),
        }

        if (order.drName.length < 4) {
            toast.error('Please enter doctors name')
            return;
        }
        if (!Number.isInteger(order.noOfDays)) {
            toast.error("Please enter number of days");
            return;
        }
        if (!selectedAddress) {
            toast.error('Please select address');
            return;
        }

        let saveOrder = await request({
            method: "POST",
            url: "requestCallback/admin/createOrder",
            data: order,
        });


        if (saveOrder.data.success) {
            toast.success('Order created successfully !');
            setModal(false)
        } else {
            toast.error("Something went wrong");
        }
    }

    return <React.Fragment >
        <CustomModalForm
            isShowing={isCreateFormShowing}
            hide={toggleCreateForm}
            title="Create User"
            style={{ width: "60%", maxWidth: "60%" }}
            className="modal-form">
            <CreateAddress selectedUser={callback?.mapUserPatient?.User} toggle={toggleCreateForm} createOrderToggler={setModal} />
        </CustomModalForm>

        <button onClick={() => {
            setModal(true)
        }} className="bg-elm-500 text-xs text-white-50 p-2 rounded-md font-sans font-semibold flex justify-center" >
            Order
        </button>

        <Modal
            show={modalToggle}
            dismissible={true}
            onClose={() => {
                setModal(false)
            }}>
            <Modal.Header style={{ backgroundColor: "white" }}>
                Create Order
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "white" }}>
                <div >
                    <Label style={{ fontWeight: 700 }} htmlFor="Name">Dr Name</Label>
                    <TextInput
                        style={{ marginTop: 0 }}
                        id="Name"
                        placeholder="Dr Name"
                        className="mb-4"
                        required={true}
                        ref={drNameRef}
                    />
                    <Label style={{ fontWeight: 700 }} htmlFor="Prescription">Prescription Date</Label>
                    <TextInput
                        id="Prescription"
                        type={"date"}
                        placeholder="Prescription Date"
                        required={true}
                        className="mb-4"
                        ref={prescriptionDateRef}
                        defaultValue={moment().format('YYYY-MM-DD')}
                    />
                    <Label style={{ fontWeight: 700 }} htmlFor="Prescription">Start Date</Label>
                    <TextInput
                        id="Prescription"
                        type={"date"}
                        placeholder="Start Date"
                        required={true}
                        className="mb-4"
                        ref={startDateRef}
                        defaultValue={moment().format('YYYY-MM-DD')}
                    />
                    <Label style={{ fontWeight: 700 }} htmlFor="Days">No of Days</Label>
                    <TextInput
                        id="Days"
                        placeholder="No of Days"
                        className="mb-4"
                        required={true}
                        ref={noOfDaysRef}
                    />
                    <Label style={{ fontWeight: 700 }}>Address</Label>
                    {modalToggle && !isAddressEmpty ? <select style={{ width: '100%' }} onChange={(e) => {
                        setselectedAddress(e.currentTarget.value);
                    }} name="address" id="address">
                        <option key={""} selected value={null}>-- select address --</option>
                        {addresses.map(address => <option key={address.id} selected={address.id == -1} value={address.id}  >{address.line + ' ' + address.state + ' ' + (address.landmark ?? '') + ' ' + address.pincode}</option>)}

                    </select> : ''}
                    {isAddressEmpty && <button className="bg-elm-500 text-white-50 p-3 mt-2 rounded-md font-sans flex justify-center text-center" onClick={() => { setModal(false); toggleCreateForm() }}>Create Address</button>}
                </div>
            </Modal.Body>
            <Modal.Footer style={{ backgroundColor: "white" }}>
                <button onClick={newOrder} className="bg-elm-500 text-white-50 p-3 rounded-md font-sans flex justify-center text-center">
                    Order
                </button>

            </Modal.Footer>
        </Modal>
    </React.Fragment >
}

export { CustomModal };
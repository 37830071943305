import React, { useEffect } from "react";
import "./App.scss";
import { RouterContaner } from "./navigation";
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import MesssageDialog from "./layouts/Dashboard/components/toast";
import { TENANTS } from "./utils/constants";
import Cookies from "js-cookie";


const cookies = Cookies.withConverter({
    write: function (value, name) {
        if (name === 'token') {
            return value
        } else {
            return value
        }
    }
})

function App() {

    useEffect(() => {
        const domain = window.location.hostname;
        if (domain && !cookies.get("tenantId")) {
            for (let i = 0; i < Object.keys(TENANTS).length; i++) {
                const key = Object.keys(TENANTS)[i];
                if (domain === key) {
                    cookies.set("tenantId", TENANTS[key], { expires: 365 });
                    break;
                }
            }
        }
    }, []);

    return (
        <div className="App" style={{ fontFamily: "CabinetGrotesk" }}>
            <MesssageDialog style={{ zIndex: 9999 }} />
            <RouterContaner />
        </div >
    );
}

export default App;

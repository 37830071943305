import React, { useRef, useState } from "react";
import morning from "../../../../assets/morning.png";
import evening from "../../../../assets/evening.png";
import afternoon from "../../../../assets/afternoon.png";
import night from "../../../../assets/night.png";
import { Rows } from "./rows";
import PropTypes from "prop-types";
import html2canvas from "html2canvas";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import DrxQr from "../drxQr";
import { TENANT_COMPANY_MAP } from "../../../../utils/constants";
import Cookies from "js-cookie";

function DRXTable(props) {
    const tenantId = Cookies.get("tenantId");
    const [tableHeight, setTableHeight] = useState("auto");
    const ref = useRef(null);
    const { rows, drName, patientName, count, prescriptionValues, getDates, index, wbn, downloadAllBigDrx, flipDownloadBigDrx } = props;
    const printRef = React.useRef();
    let divName = `drx-table-${index}`;
    const { orderId } = useParams()
    const generatePNG = async () => {
        try {
            let divToDisplay = document.getElementById(divName);
            let canvas = await html2canvas(divToDisplay);
            let data = canvas.toDataURL("image/png");
            let link = document.createElement("a");

            if (typeof link.download === "string") {
                link.href = data;
                link.download = `BigBox_${patientName}_${orderId}.png`;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            else {
                window.open(data);
            }
            if (downloadAllBigDrx) {
                flipDownloadBigDrx();
            }
        } catch (error) {
            console.log(error)
        }
    };
    useEffect(() => {
        if (downloadAllBigDrx) {
            generatePNG();
        }
    }, [downloadAllBigDrx])
    useEffect(() => {
        setTableHeight(ref.current ? ref.current.offsetWidth : 0)
    }, [ref.current]);
    return (
        <div className="p-5">
            <div
                id={divName}
                ref={printRef}
                style={{
                    width: "85vw",
                    overflow: "hidden",
                    maxWidth: "1400px",
                    height: tableHeight,
                    border: "3px solid black",
                }}
            >
                <table className="h-auto border-none" style={{ width: "100%" }} ref={ref}>
                    <thead>
                        <tr
                            className="bg-[#dbdbdb]"
                            style={
                                count >= 10
                                    ? {
                                        width: "100%",
                                        height: "150px",
                                        maxHeight: "100px",
                                    }
                                    : {
                                        width: "100%",
                                        height: "150px",
                                        maxHeight: "150px",
                                    }
                            }
                        >
                            {/* All Data of Row */}
                            <th>
                                <div className="text-left px-4 flex flex-col gap-2">
                                    <h2 className="text-3xl font-sans mb-1" contentEditable={true}> {patientName}</h2>
                                    <h2 className="text-2xl font-sans">
                                        {getDates.StartDate}- {getDates.EndDate}
                                    </h2>
                                    <h2
                                        className={`text-xl text-gray-600 capitalize font-sans ${count >= 10 ? "mb-4" : ""
                                            }`}
                                    >
                                        {drName}
                                    </h2>
                                </div>
                            </th>
                            {/* Morning */}
                            <th>
                                <div className="flex flex-col items-center">
                                    <img
                                        src={morning}
                                        alt="morning"
                                        className={`object-fit w-[50px] h-[50px] mb-2`}
                                    />
                                    <h2 className="text-xl">Morning</h2>
                                </div>
                            </th>
                            {/* Afternoon */}
                            <th>
                                <div className="flex flex-col items-center">
                                    <img
                                        src={afternoon}
                                        alt="afternoon"
                                        className="object-fit w-[50px] h-[50px] mb-2"
                                    />
                                    <h2 className="text-xl">Afternoon</h2>
                                </div>
                            </th>
                            {/* Evening */}
                            <th>
                                <div className="flex flex-col items-center">
                                    <img
                                        src={evening}
                                        alt="evening"
                                        className="object-fit w-[50px] h-[50px] mb-2"
                                    />
                                    <h2 className="text-xl">Evening</h2>
                                </div>
                            </th>
                            {/* Night */}
                            <th>
                                <div className="flex flex-col items-center">
                                    <img
                                        src={night}
                                        alt="night"
                                        className="object-fit w-[50px] h-[50px] mb-2"
                                    />
                                    <h2 className="text-xl">Night</h2>
                                </div>
                            </th>
                            {/* Quanity */}
                            <th className=" relative" style={wbn ? { width: "200px" } : {}}>
                                <div className="flex justify-center text-end text-xl absolute bottom-[15px] left-[15px]">
                                    <h2>Qty</h2>
                                </div>
                            </th>
                            {/* Details+ */}
                            {wbn ? null : (
                                <th className="p-2">
                                    {tenantId && tenantId !== TENANT_COMPANY_MAP["EAZY"] && <div className="items-center justify-center">
                                        <div className="flex flex-col items-center ">
                                            <DrxQr id={orderId} />
                                            <h2 className="text-xl">Details</h2>
                                        </div>
                                    </div>}
                                </th>
                            )}
                        </tr>
                    </thead>

                    <tbody>
                        {getDates && prescriptionValues?.rows.map((med, index) => (
                            <Rows
                                {...med}
                                prescription={med}
                                square={true}
                                wbn={wbn}
                                key={index}
                                length={count}
                                StartDate={getDates?.StartDate}
                                EndDate={getDates?.EndDate}
                                // height={825}
                                qty={() => {
                                    if (rows[index].Duration < 1) {
                                        return med.Qty;
                                    }
                                    return (
                                        rows[index].time.length *
                                        rows[index].Duration *
                                        rows[index].item.quantity
                                    );
                                }}
                            />
                        ))}
                        {count > 0 && (
                            <>
                                {Array.from({ length: 25 - count }).map((_, i) => (
                                    <tr
                                        className="border-2 border-[#e5e7eb]"
                                        style={{ height: `100px` }}
                                        key={i}
                                    >
                                        <td
                                            className={`w-[270px] border-2 border-[#e5e7eb] pb-6`}
                                        >
                                            <div className="flex items-start px-4 flex-col justify-start text-left"></div>
                                        </td>

                                        <td className="w-[200px] border-2 border-[#e5e7eb]"></td>
                                        <td className="w-[200px] border-2 border-[#e5e7eb]"></td>
                                        <td className="w-[200px] border-2 border-[#e5e7eb]"></td>
                                        <td className="w-[200px] border-2 border-[#e5e7eb]"></td>

                                        <td className="w-[50px] border-2 border-[#e5e7eb]">
                                            <div className="flex items-center w-full justify-center">
                                                <div className="p-3 text-left text-2xl px-4 w-16 leading-3"></div>
                                            </div>
                                        </td>
                                        {wbn ? null : (
                                            <td className="w-[200px] border-2 border-[#e5e7eb]">
                                                <div className="flex items-start w-full flex-col justify-center px-4">
                                                    <div className="text-left text-sm flex flex-col">
                                                        <tr className=""></tr>
                                                        <tr className=""></tr>
                                                        <tr className=""></tr>
                                                    </div>
                                                </div>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </>
                        )}
                    </tbody>
                </table>
            </div>
            <button
                onClick={generatePNG}
                className="mt-6 mb-6 p-2 bg-[#28A48B] text-white font-semibold px-6 rounded-2xl"
                style={{ color: "white" }}
            >
                Download BigBox
            </button>
        </div>
    );
}

DRXTable.propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    rows: PropTypes.array,
    drName: PropTypes.string,
    patientName: PropTypes.string,
    total: PropTypes.any,
    ref: PropTypes.any,
    count: PropTypes.any,
    prescriptionValues: PropTypes.any,
    getDates: PropTypes.any,
    index: PropTypes.any,
    wbn: PropTypes.bool,
    downloadAllBigDrx: PropTypes.bool,
    flipDownloadBigDrx: PropTypes.func,
};
export { DRXTable };

import React from "react";
import pillupLogo from "../../../../assets/logo.png";
import { RouteConstants } from "../../../../config/constants/route";
import { AiOutlineTable } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import "./sidebar.scss";
import { HiHome, HiUsers } from "react-icons/hi";
import { MdUpcoming, MdLocalPhone, MdMedicalServices, MdDeliveryDining, MdLocalOffer } from "react-icons/md";

function Sidebar() {
    const navigations = [
        {
            name: "Home",
            icon: <HiHome style={{ fontSize: "22px" }} />,
            link: RouteConstants.HOME,
        },
        {
            name: "Reorders",
            icon: <MdUpcoming style={{ fontSize: "22px" }} />,
            link: RouteConstants.FUTUREORDERS,
        },

        {
            name: "Orders",
            icon: <AiOutlineTable style={{ fontSize: "22px" }} />,
            link: RouteConstants.ALLORDERS,
        },
        {
            name: "Users",
            icon: <HiUsers style={{ fontSize: "22px" }} />,
            link: RouteConstants.USERS,
        },
        {
            name: "Medicines",
            icon: <MdMedicalServices style={{ fontSize: "22px" }} />,
            link: RouteConstants.Medicine_Master,
        },
        {
            name: "Callbacks",
            icon: <MdLocalPhone style={{ fontSize: "22px" }} />,
            link: RouteConstants.REQUEST_CALLBACK,
        },
        {
            name: "Delivery",
            icon: <MdDeliveryDining style={{ fontSize: "22px" }} />,
            link: RouteConstants.DELIVERY,
        },
        {
            name: "Marketing",
            icon: <MdLocalOffer style={{ fontSize: "22px" }} />,
            link: RouteConstants.MARKETING,
        },

    ];
    return (
        <div className="sidebar-container sticky h-[100vh]">
            {/* BRAND LOGO */}
            <div className="flex-start ml-3">
                <img src={pillupLogo} alt="Pillup Logo" className="mt-5 w-[70%]" />
            </div>
            {/* NAVIGATION */}
            <div className="flex-start">
                <div className="mt-10 flex flex-col justify-start items-start gap-3">
                    {navigations.map((nav, index) => (
                        // <div>
                        <NavLink
                            key={index}
                            style={{ width: "98%" }}
                            className={(data) => {
                                return data.isActive ? "navlink-item rounded-2xl shadow-soft-2xl w-100" : "navlink-item-active w-100";
                            }}
                            to={nav.link}>
                            <div
                                key={index}
                                className="flex items-center hover ? 'bg-[#158D7B]' : 'bg-[#158D7B]'">
                                <div className="text-xl">{nav.icon}</div>
                                <div className="ml-4 font-sans navnames">{nav.name}</div>
                            </div>
                        </NavLink>
                        // </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
